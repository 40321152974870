.btn {
    display: inline-flex;
    position: relative;
    /*display      : -webkit-inline-flex;*/
    display: -ms-inline-flexbox;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    @include rem-fallback(font-size, 16);
    @include rem-fallback(line-height, 25);

    border-radius: 2.3rem;
    /* @include rem-fallback(border-radius, 23 ); */

    @include rem-fallback(height, 46);
    @include rem-fallback(padding, 2, 20);

    border: 0.1rem solid $gray-800;
    background: $gray-800;
    color: var(--white, $white);

    &.active,
    &:hover,
    &:focus,
    &:active {
        color: $gray-800;
        border: 0.1rem solid var(--white, $white);
        border-radius: 2.3rem;
        box-shadow: none;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        @include box-shadow(none);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        @include box-shadow($btn-active-box-shadow);

        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }

    &.unsetHeight {
        height: unset;
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-small {
    @include rem-fallback(border-radius, 18);
    @include rem-fallback(font-size, 14);

    // @include rem-fallback(height, 36);
    @include rem-fallback(line-height, 18);
    @include rem-fallback(padding, 2, 15);
    height: 3.3rem;
}

.btn-minimal {
    height: initial;
    padding: 0.5rem;
    align-items: initial;
    justify-content: left;
    background: transparent;
    color: initial;
    border: 0.2rem solid transparent;

    &:hover,
    &:active,
    &:focus {
        background-color: rgba(255, 255, 255, 0.1);
        border: 0.2rem solid var(--white, $white);
        padding: 0.5rem;
    }
}

.btn-transp {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: transparent;

    &:hover,
    &:focus,
    &:active {
        background-color: rgba(255, 255, 255, 0.05);
        border-color: var(--white, $white);
    }
}

.btn-primary {
    &.active,
    &:hover,
    &:active {
        background: var(--white, $white);
        color: var(--primary, $primary);
        border-color: var(--primary, $primary);
        img{
            filter: brightness(0) saturate(100%) invert(54%) sepia(34%) saturate(705%) hue-rotate(60deg) brightness(101%)
                contrast(92%);
        }
    }

    &:focus {
        border-color: var(--primary, $primary);
    }

    // &:focus {
    //     color: $secondary;
    //     border-color: $secondary;
    // }

    &:disabled,
    &.disabled {
        background: var(--primary, $primary);
        color: var(--white, $white);
    }

    border-color: var(--primary, $primary);
    background: var(--primary, $primary);
    color: var(--white, $white);
}

.btn-inverse {
    color: $gray-600;
    background-color: var(--white, $white);
    border: 0.1rem solid $gray-400;

    &:hover,
    &:focus,
    &:active {
        border-color: $gray-800;
        color: $gray-800;
    }

    &.btn-primary {
        &:hover,
        &:focus,
        &:active {
            border-color: var(--primary, $primary);
            color: var(--primary, $primary);
        }
    }

    &.btn-danger {
        border-color: var(--red, $red);
        color: var(--red, $red);

        &:hover,
        &:focus,
        &:active {
            border-color: $gray-800;
            color: $gray-800;
        }
    }
}

.btn-link {
    height: fit-content;
    padding: 0;
    white-space: initial;
    align-items: initial;
    justify-content: left;
    border: none;
    background: transparent;
    color: var(--link-color, $link-color);
    border-radius: 0.2em;
    font-size: inherit;
    line-height: inherit;
    @include rem-fallback(min-width, 20);

    &:hover,
    &:active {
        color: var(--primary, $primary);
        padding: 0;
        border: none;
    }

    &:focus {
        color: initial;
        padding: 0;
        border: none;
        box-shadow: 0 0 1pt 1pt var(--ln-focus-color, $ln-focus-color);
        border-radius: 0.2em;
    }

    &.disabled {
        &:focus,
        &:hover,
        &:active {
            color: initial;
            border: initial;
            padding: initial;
            box-shadow: none;
            
        }
    }
    &.block{
        color: initial;
        display: block;
        text-align: initial;
        border-radius: 0;
    }
}

.btn-ring-white {
    height: 3rem;
    width: 3rem;
    border: none;
    background: transparent;
    border-radius: 50%;
    border: 2px solid var(--white, $white);
    overflow: hidden;

    * {
        line-height: 45%;
    }

    &:hover,
    &:active {
        color: var(--secondary, $secondary);
        border-color: var(--secondary, $secondary);
    }

    &:focus {
        color: initial;
        box-shadow: 0 0 1pt 1pt var(--secondary, $secondary);
    }
}

.btn-ring-dark {
    height: 3rem;
    width: 3rem;
    border: none;
    background: transparent;
    border-radius: 50%;
    border: 2px solid var(--secondary, $secondary);
    overflow: hidden;

    * {
        line-height: 45%;
        position: relative;
        top: 0.1rem;
    }

    &:hover,
    &:active {
        color: var(--primary, $primary);
        border-color: var(--primary, $primary);
    }

    &:focus {
        color: initial;
        box-shadow: 0 0 1pt 1pt var(--ln-focus-color, $ln-focus-color);
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .btn {
        @include rem-fallback(min-width, 100);
    }
}

.modal-header button.close,
button.close.round {
    background-color: var(--primary, $primary);
    border-radius: 50%;
    
    height: 3rem;
    width: 3rem;
    color: var(--white, $white);
    padding: 0;
    margin: 0;
    font-weight: normal;
    text-shadow: none;
    opacity: 1;
    &:hover {
        color: var(--white, $white);
    }

    padding: 0;
    margin: 0;

}

button.edit.round {
    background-color: var(--primary, $primary);
    border-radius: 50%;
    border:none;
    height: 3rem;
    width: 3rem;
    color: var(--white, $white);
    padding: 0;
    margin: 0;
    font-weight: normal;
    text-shadow: none;
    opacity: 1;
   

    &:hover {
        background-color: var(--white, $white);
        img{
            filter: brightness(0) saturate(100%) invert(54%) sepia(34%) saturate(705%) hue-rotate(60deg) brightness(101%)
                    contrast(92%);
        }
        border: 0.1rem solid var(--primary, $primary);
    }
    img{
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%)
            contrast(102%);
        
        position: relative;
        top: -0.1rem;
    }

    &.inverse{
        background-color: var(--white, $white);
        border: 0.1rem solid var(--primary, $primary);
        img{
            filter:none;
        }
        &:hover {
            background-color: var(--primary, $primary);
            img{
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%)
                        contrast(102%);
            }
            border: none;
        }
    }

    padding: 0;
    margin: 0;
}
