.custom-control {
    &.intermediateState {
        .custom-control-input {
            &:checked ~ .custom-control-label::before {
                color: $custom-control-indicator-checked-color;
                border-color: var(--info, $info);
                background-color: var(--info, $info);
            }
        }
    }
}

.custom-control {
    &.started {
        .custom-control-input {
            &:checked ~ .custom-control-label::before {
                color: $custom-control-indicator-checked-color;
                border-color: var(--yellow, $yellow);
                background-color: var(--yellow, $yellow);
            }
        }
    }
}

.custom-control {
    &.failedState {
        .custom-control-input {
            &:checked ~ .custom-control-label::before {
                color: $custom-control-indicator-checked-color;
                border-color: var(--red, $red);
                background-color: var(--red, $red);
            }
        }
    }
}