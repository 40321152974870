@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";
@import "assets/scss/base/colors.scss";

.tab-content {
    border: 1px solid $border-grey-dark;
    border-top: none;
}

:global(.nav-tabs){
    border-bottom: none;
    
}

:global(.nav-tabs .nav-link){
    border-bottom: 1px solid $nav-tabs-border-color;
}

:global(.nav-tabs .nav-item){
    margin-bottom: 0;
}

.mail-form {
    @include rem-fallback(max-width, 820);
    width: 100%;
}

.file-icon {
    @include rem-fallback(width, 44);
    @include rem-fallback(height, 44);
}

.deleteButton{
    position: absolute;
    right: 0.1rem;
    z-index: 1;
}
