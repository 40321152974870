@import url('ptsans.scss');

body{
  font-family: var(--font-family-sans-serif, $font-family-sans-serif);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $secondary;
  margin: 0;
}

b, strong{
  color: $secondary;
}