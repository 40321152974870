/* LN overrides of FullCalendar styles */
.ln-fullcalendar {
  .fc-event,
  .fc-event-dot {
    color: $white;
    background-color: var(--primary, $primary);
    border: 0.1rem solid var(--primary, $primary);
    &.event-other {
      color: $secondary;
      background-color: $white;
      border: 0.1rem solid $grey_5;
    }
  }
  // .fc-event:hover {
  //   color: $gray-300;
  //   background-color: $gray-100;
  // }
  .fc-event,
  .fc-list-item {
    cursor: pointer;
    &.event-other .fc-event-dot {
      color: $secondary;
      background-color: $white;
      border: 0.1rem solid $grey_5;
    }
  }

  .fc-event {
    &.ttip {
      --tooltip-offset: calc(100% + 1px + var(--tooltip-tail, 0px));
      text-align: center;
      --tooltip-tail: 5px;
      --tooltip-color: $primary;
      --tooltip-text-color: $white;
      --tooltip-tail-offset: calc(100% + 1px - var(--tooltip-tail));
      &:hover:before,
      &:hover:after {
        opacity: 1;
        transition-delay: 75ms;
        z-index: 100;
      }
      &:before,
      &:after {
        opacity: 0;
        transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
        transition-duration: 0.15s;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-delay: 0.1s;
        white-space: pre-line;
      }
      &:before {
        position: absolute;
        pointer-events: none;
        content: attr(data-tip);
        transform: translateX(-50%);
        top: auto;
        left: 50%;
        right: auto;
        bottom: var(--tooltip-offset);
        border-radius: 0.25rem;
        font-size: 1.4rem;
        line-height: 2rem;
        max-width: 20rem;
        padding: 0.25rem 0.5rem;
        background-color: $primary;
        color: $white;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
      }
      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        display: block;
        transform: translateX(-50%);
        border: var(--tooltip-tail,0) solid transparent;
        border-top: var(--tooltip-tail,0) solid var(--tooltip-color);
        top: auto;
        left: 50%;
        right: auto;
        bottom: var(--tooltip-tail-offset);
      }
    }
  }

  .fc-ltr {
    .fc-list-heading-main,
    .fc-list-heading-alt {
      float: unset;
      @include rem-fallback(padding-right, 10);
    }
  }
  .fc-button-group {
    margin-bottom: 1.5rem;
    .fc-button:first-child {
      border-left: 0.1rem solid $gray-400;
    }
    .fc-button:last-child {
      border-right: 0.1rem solid $gray-400;
    }
  }

  .fc-view-container {
    max-width: 70rem;
    margin: auto;
  }

  .fc-button {
    align-items: center;
    border-radius: 2.3rem;
    display: inline-flex;
    /*display      : -webkit-inline-flex;*/
    display: -ms-inline-flexbox;
    @include rem-fallback(font-size, 16);
    @include rem-fallback(height, 36);
    justify-content: center;
    @include rem-fallback(line-height, 25);
    @include rem-fallback(padding, 2, 10);

    border-top: 0.1rem solid $gray-400;
    border-bottom: 0.1rem solid $gray-400;
    border-left: unset;
    border-right: unset;
    background: #f0f0f099;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      color: $gray-800;
      box-shadow: none;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      color: $grey_1;
      opacity: unset;
      @include box-shadow(none);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      @include box-shadow($btn-active-box-shadow);

      &:focus {
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
      }
    }
  }

  // Future-proof disabling of clicks on `<a>` elements
  a.fc-button.disabled,
  fieldset:disabled a.fc-button {
    pointer-events: none;
  }

  .fc-button-primary {
    &:hover,
    &:active,
    &.fc-button-active {
      background: var(--primary, $primary);
      color: $white;
      border-color: var(--primary, $primary);
    }

    &:focus {
      color: $secondary;
      border-color: $secondary;
    }

    &:disabled,
    &.disabled {
      background: #f0f0f099;
      color: $grey_1;
      border-top: 0.1rem solid $gray-400;
      border-bottom: 0.1rem solid $gray-400;
      &:hover {
        border-left: none;
        border-right: none;
      }
    }

    background-color: #f0f0f099;
    border-top: 0.1rem solid $gray-400;
    border-bottom: 0.1rem solid $gray-400;
    border-left: unset;
    border-right: unset;
    color: $gray-600;
  }

  .fc-button-primary:not(:disabled):active,
  .fc-button-primary:not(:disabled).fc-button-active {
    background: var(--primary, $primary);
    color: $white;
    border-color: var(--primary, $primary);
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .fc-button {
      @include rem-fallback(min-width, 100);
    }
  }

  .fc-center {
    h2 {
      font-size: 2rem;
      line-height: 2.5rem;
      padding-left: 0.5rem;
    }
  }

  .fc-header-toolbar {
    position: relative;
  }

  // @include media-breakpoint-up(sm) {
  //   .fc-header-toolbar {
  //     .fc-center {
  //       position: absolute;
  //       width: 100%;
  //       text-align: center;
  //     }
  //   }
  // }

  .fc-button {
    font-size: 1.4rem;
  }

  .fc-toolbar {
    align-items: unset;
    flex-wrap: wrap;
    .fc-left {
      order: 1;
      margin-right: 0.5rem;
    }
    .fc-center {
      order: 3;
      margin-top: 0.7rem;
      width: 100%;
      text-align: center;
    }
    .fc-right {
      order: 2;
    }
  }
}
