@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";


.fileIcon {
    @include rem-fallback(width, 34);
    @include rem-fallback(height, 34);
}

.dropDownMenu {
    width: max-content;
    a {
        color: unset;
    }
}

.smallShow, .smallIcons {
    display: none !important;
}

.storageLabel {
    @include rem-fallback(font-size, 14);
}

.treeList {
    @include rem-fallback(margin-left, 20);
    list-style: none;
    .treeListItem img {
        @include rem-fallback(margin-bottom, 2);
    }
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {

    .additionalTxt {
        @include rem-fallback(font-size, 14);
    }

    .docBox tbody tr {
        border-bottom: 1px solid #eee;
    }

    .docBox tbody tr td {
        border-bottom: none !important;
        padding: 0;
    }

    .smallIcons {
        display: flex !important;
        position: absolute;
        right: 0;
        @include rem-fallback(top, 2);
    }

    .flexColumn {
        flex-direction: column;
    }

    .headerBtnWrapper {
        width: min-content;
    }

    .btnMargin {
        display: block;
        @include rem-fallback(margin-right, 44);
    }

    .noSidePadding {
        padding-left: unset;
        padding-right: unset;
    }

    .smallShow {
        display: inline !important;
    }
    .smallHide {
        display: none !important;
    }

    .treeList {
        @include rem-fallback(margin-left, 0);
        @include rem-fallback(padding-inline-start, 10);
    }
}
