.slideInFromLeft {
    animation-duration: 0.4s;
    animation-name: leftslidein;
    position: relative;
  }

  
@keyframes leftslidein {
    from {
      left: -2000px;
    }
  
    to {
      left: 0;
    }
  }


  .slideInFromRight {
    animation-duration: 0.4s;
    animation-name: rightslidein;
    position: relative;
  }

  
@keyframes rightslidein {
    from {
      left: 2000px;
    }
  
    to {
      left: 0;
    }
  }


  .slideOutToLeft {
    animation-duration: 0.4s;
    animation-name: leftslideout;
    position: relative;
  }

  
@keyframes leftslideout {
    from {
      left: 0;
    }
  
    to {
      left: -2000px;
    }
  }

  .slideOutToRight {
    animation-duration: 0.4s;
    animation-name: rightslideout;
    position: relative;
  }

  
@keyframes rightslideout {
    from {
      left: 0;
    }
  
    to {
      left: 2000px;
    }
  }