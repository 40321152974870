@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";

.ln-card {
  @include rem-fallback(max-width, 500);
  @include rem-fallback(min-width, 300);
  border: none;
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

.ln-card-img-wrapper {
  border-radius: 0;
  // border: solid 1px rgba(0, 0, 0, 0.125);
  padding: unset;
  position: relative;
  img {
    border-radius: unset;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
}

.ln-card-body {
  padding: 0 1.25rem;
}

.ln-card-footer {
  padding: 0.75rem 1.25rem;
}

.ln-group-card-header {
  .toggle-icon {
    position: absolute;
    @include rem-fallback(top, 10);
    @include rem-fallback(right, 10);
    @include rem-fallback(width, 20);
  }
}

@media (max-width: 479px) {
  .ln-card {
    min-width: 100%;
  }
}
