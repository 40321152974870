/* styles for ImagePicker feature. LäraNära Portal */

@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables.scss";
@import "assets/scss/core/mixins/_breakpoints.scss";

.thumbBox {
    width: 7rem;
    height: 7rem;
    > img {
        max-height: 100%;
    }

    &:hover {
        img {
            opacity: 0.5;
        }
    }
}

.imgDialog {
    height: calc(100% - 3rem);
    :global(.modal-content) {
        height: 100%;
        position: relative;
        :global(.modal-body) {
            position: relative;
            div.imgList {
                position: absolute;
                bottom: 0;
                //left: 0;
                //right: 0;
                top: 10rem;
                overflow: auto;
            }
            div.scrollPanel {
                position: absolute;
                bottom: 0;
                left: 3rem;
                top: 7rem;
                overflow: auto;
                right: 2rem;
                @include media-breakpoint-down(sm) {
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}
