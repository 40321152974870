@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "../../assets/scss/core/variables";
@import "../../assets/scss/base/colors.scss";
@import "../../assets/scss/core/functions";
@import "../../assets/scss/base/mixins.scss";
@import "../../assets/scss/core/mixins";

.sidebarOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $modal-backdrop-bg;
    opacity: $modal-backdrop-opacity;
    z-index: 9; // 1 les than ksidebar
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.sideBar {
    background-color: $white;
    @include rem-fallback(width, 275);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    overflow: auto;
    @include box-shadow(1.414px, -1.414px, 5px, $black_2);
    @include transition(all 0.3s);

    @include media-breakpoint-down(md) {
        @include rem-fallback(left, -275);
    }

    @include media-breakpoint-down(sm) {
        @include rem-fallback(padding, 10);
    }

    &.forcedState {
        @include media-breakpoint-down(md) {
            left: 0;
        }

        @include media-breakpoint-up(lg) {
            @include rem-fallback(left, -275);
        }
    }

    .menuBox {
        position: absolute;
        top: 92px;
        bottom: 0;
        width: 100%;
        padding: 0 15px 0 15px;

        overflow-y: auto;
    }

    .sidebarClose {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
        padding: 1rem;
        height: 7rem;
        :global(.dark-caret-point-left) {
            &::before {
                border-top: 3rem solid transparent;
                border-right: 1rem solid var(--primary, $primary);
                border-bottom: 3rem solid transparent;
                height: 4rem;
                width: 2rem;
            }
        }
    }
}

.wrapper {
    height: 100%;

    @include transition(all 0.3s);
    position: relative;
    @include rem-fallback(margin, 0, 0, 0, 275);

    @include media-breakpoint-down(md) {
        margin: 0;
    }

    @media print {
        margin: 0;
    }

    &.forcedState {
        // @include media-breakpoint-down(md) {
        //     @include rem-fallback(margin, 0, 0, 0, 275);
        // }

        @include media-breakpoint-up(md) {
            margin: 0;
        }
    }
}

.notfoundlogo {
    max-width: 20rem;
}

.siteHeader {
    background-color: var(--primary, $primary);
    z-index: 2;
    position: relative;
    height: 8.5rem;

    @include media-breakpoint-down(md) {
        height: 10.5rem;
    }

    @include transition(all 0.3s);

    h2 {
        font-size: $font-size-base * 2;
        color: $white;
    }

    .rightMenu{
        @include media-breakpoint-down(xs) {
            transform:scale(0.8,0.8);
        }   
    }

    .showSidebar {
        position: absolute;
        left: 0;
        top: 0;
        padding: 1rem;
        z-index: 2;
        height: 7rem;
        :global(.white-caret-point-right) {
            &::after {
                border-top: 3rem solid transparent;
                border-right: 0;
                border-bottom: 3rem solid transparent;
                border-left: 1rem solid;
                height: 4rem;
                width: 2rem;
                color: $white;
            }
        }
    }

}

.toogleSidebarBtn {
    @include rem-fallback(width, 27);
    @include rem-fallback(height, 25);
    position: relative;

    cursor: pointer;
    @include rem-fallback(margin, 0, 28, 0, 0);

    @include media-breakpoint-down(lg) {
        @include rem-fallback(margin-right, 15);
    }

    .menu-icon {
        position: absolute;
        @include rem-fallback(height, 2);
        @include rem-fallback(width, 23);
        left: 0;
        top: 40%;
        background-color: $white;

        &:before,
        &:after {
            content: "";
            position: absolute;
            @include rem-fallback(height, 2);
            background-color: $white;
        }

        &:before {
            @include rem-fallback(width, 27);
            @include rem-fallback(top, -9);

            @include media-breakpoint-down(xs) {
                @include rem-fallback(top, -8);
            }
        }

        &:after {
            @include rem-fallback(width, 17);
            @include rem-fallback(bottom, -9);

            @include media-breakpoint-down(xs) {
                @include rem-fallback(bottom, -8);
            }
        }
    }
}

.headerLeft {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    // @include media-breakpoint-down(sm) {
    //     width: 100%;
    //     @include rem-fallback(margin, 0, 0, 15);
    // }

    // @include media-breakpoint-down(xs) {
    //     -webkit-box-pack       : justify;
    //     -webkit-justify-content: space-between;
    //     -ms-flex-pack          : justify;
    //     justify-content        : space-between;
    // }
}

.side-menubar-btn {
    @include rem-fallback(width, 27);
    @include rem-fallback(height, 25);
    position: relative;
    top: -1rem;
    cursor: pointer;
    @include rem-fallback(margin, 0, 28, 0, 0);

    @include media-breakpoint-down(lg) {
        @include rem-fallback(margin-right, 15);
    }

    .menu-icon {
        position: absolute;
        @include rem-fallback(height, 2);
        @include rem-fallback(width, 23);
        left: 0;
        top: 40%;
        background-color: $white;

        &:before,
        &:after {
            content: "";
            position: absolute;
            @include rem-fallback(height, 2);
            background-color: $white;
        }

        &:before {
            @include rem-fallback(width, 27);
            @include rem-fallback(top, -9);

            @include media-breakpoint-down(xs) {
                @include rem-fallback(top, -8);
            }
        }

        &:after {
            @include rem-fallback(width, 17);
            @include rem-fallback(bottom, -9);

            @include media-breakpoint-down(xs) {
                @include rem-fallback(bottom, -8);
            }
        }
    }
}

.header-text {
    // @include media-breakpoint-down(xs) {
    //     text-align: right;
    // }

    .oneClass {
        @include rem-fallback(padding, 0, 0, 0, 20);
        color: $white;
    }

    @include rem-fallback(height, 75);

    .dropdown-text {
        @include rem-fallback(width, 270);
        @include rem-fallback(padding, 0, 5);

        // @include media-breakpoint-down(lg) {
        //     @include rem-fallback(width, 235);
        // }

        // @include media-breakpoint-down(xs) {
        //     width                 : 100%;
        //     padding               : 0;
        //     -webkit-box-orient    : horizontal;
        //     -webkit-box-direction : reverse;
        //     -webkit-flex-direction: row-reverse;
        //     -ms-flex-direction    : row-reverse;
        //     flex-direction        : row-reverse;
        // }

        span {
            @include rem-fallback(padding-left, 16);
        }
    }

    h4 {
        color: $white;
        font-weight: 400;
        @include rem-fallback(padding, 0, 0, 0, 20);

        @include media-breakpoint-down(md) {
            @include rem-fallback(padding, 0, 0, 0, 20);
        }

        @include media-breakpoint-down(xs) {
            @include rem-fallback(padding, 0, 0, 0, 20);
        }
    }
}

.currentClassToggle {
    cursor: pointer;
    overflow: hidden;
    max-height: 8.4rem;

    h2 {
        color: $white;
        max-width: 40rem;
        max-height: 6.5rem;
    }
    span {
        margin-right: 0.2em;
        position: relative;
        top: 0.2rem;
        
    }
}

.dropDownMenu {
    display: none;

    position: absolute;
    background-color: $white;
    padding: 1rem 0rem;

    div {
        padding: 0;
        a,
        div {
            display: block;
            font-size: 2rem;
            color: $black;

            &:hover,
            &:active,
            &:focus {
                background-color: var(--primary, $primary);
                outline: none;
                color: $white;
                text-decoration: none;
            }
        }
    }
}

.dropDownItem {
    min-width: 4.6rem;
    min-height: 4.6rem;
    //
    img {
        object-fit: initial;
        margin-right: 0.25rem;
    }
}

.headerRight {
    z-index: 4;
}
.dropClass {
    background-color: var(--primary, $primary);
    text-align: center;
    padding-right: 1rem;
    border-color: var(--primary, $primary);
}

.header-listing {
    margin: 0;
    padding: 0;
    list-style-type: none;

    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    li {
        cursor: pointer;
        list-style-type: none;
    }
}

.myGroupDropdown {
    @include rem-fallback(margin, 0, 20, 0, 0);

    .dropDownItem:hover {
        border-width: 0.2rem;
        padding: 0.1rem 1.9rem;
    }

    h3 {
        color: $white;
        font-weight: normal;
        min-width: 13rem;
        display: inline-block;
    }

    span.caret {
        position: relative;
        top: 1.15rem;
        left: 0.5rem;
    }
}

.notification {
    position: relative;
    @include rem-fallback(margin, 0, 20, 0, 0);
    img {
        position: relative;
        top: 0.3rem;
        left: 0.3rem;
        @include rem-fallback(width, 25);

        @include media-breakpoint-down(xs) {
            @include rem-fallback(width, 20);
        }
    }

    .notification-badge {
        position: absolute;
        @include rem-fallback(top, -1);
        @include rem-fallback(right, 4);
        @include rem-fallback(font-size, 11);
        @include rem-fallback(line-height, 16);
        color: $white;
        font-weight: 400;
        background-color: $red;
        @include rem-fallback(width, 17);
        @include rem-fallback(height, 17);
        border-radius: 50%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;

        @include media-breakpoint-down(xs) {
            @include rem-fallback(line-height, 15);
            @include rem-fallback(width, 15);
            @include rem-fallback(height, 15);
            @include rem-fallback(top, -6);
            @include rem-fallback(right, -5);
        }
    }
}

.admin {
    padding: 1.1rem 0.8rem 0.8rem 1.1rem;
    border-radius: 2.5rem;
    &:hover {
        border: 0.2rem solid $white;
        padding: 0.9rem 0.6rem 0.6rem 0.9rem;
    }
    height: 4.6rem;
    width: 4.6rem;
}

.search {
    @include rem-fallback(margin, 0, 20, 0, 0);
    position: relative;

    img {
        @include rem-fallback(margin, 3, 0, 3, 4);
        @include rem-fallback(width, 20);
    }

    .searchMenu {
        position: fixed;
        top: 89px;
        bottom: 3px;
        right: 0;
        max-width: 600px;
        overflow: auto;

        transition: transform 0.3s ease-out;
    }
}

.user-info {
    .user-img {
        @include rem-fallback(width, 42);
        @include rem-fallback(height, 42);
        display: inline-block;

        @include rem-fallback(border-radius, 21);
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;

        img {
            object-fit: cover;
        }
    }

    span.caret {
        position: absolute;
        right: -1.7rem;
        top: 1rem;
    }
}

.country-dropdown-list {
    @include rem-fallback(margin, 0, 25, 0, 0);
    position: relative;
    left: -1rem;
}
