@import "./lnvariables.scss";
@import "./base/colors.scss";

:root {
    // time-keeper
    --top-selected-color: #{$primary};
    --top-text-color: #{$gray-900};
    --numbers-text-color: #{$gray-900};
    --hand-circle-outer: #{$primary-lighter};
    --hand-line-color: #{$primary-lighter};
    --meridiem-selected-bg-color: #{$primary};
    --meridiem-selected-text-color: #{$gray-900};

    // donut
    @each $color, $value in $theme-colors {
        --donut-#{$color}: #{$value};
    }

    --link-color: #{$link-color};
    --link-hover-color: #{$primary};

    --primary-light: #{$primary-light};
    --primary-lighter: #{$primary-lighter};
    --primary-lightest: #{$primary-lightest};

    --primary-darker: #{$primary-darker};

    --user-bg-color: #{$primary-lighter};
    --warning-light: #{$warning-light};


    // LäraNära 
    --violet: #{$violet};
    --ln-focus-color: #{transparentize(  $primary, 0.3)};

    --siteLogoUrl: "/imgs/logo.png";

    --body-text-color: #{$gray-900};
}
