.badge.badge-pill {
    @include rem-fallback(font-size, 12);
    @include rem-fallback(line-height, 18);
    @include rem-fallback(padding-bottom, 2);
    
    &.fixedSize{
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      padding-top: 0.1rem;
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
    }
  }


.badge.fit {
  width: fit-content;

}

.badge-white {
  background-color: white;
}