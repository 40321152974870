@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";



.row-link {
    cursor: pointer;
}

.td-xs {
    width: 10%;
}

.td-s {
    width: 15%;
}
.td-l {
    width: 75%;
}

.pre-wrap {
    white-space: pre-wrap !important;
}

.forum-text-wrapper {
    @include rem-fallback(margin-left, -44);
    @include rem-fallback(margin-top, 20);
}

.forum-title {
    font-weight: 700;
}

.forum-date {
    color: gray;
    .icon {
        @include rem-fallback(height, 18);
        @include rem-fallback(width, 18);
    }
}

.forum-head {
    .forum-date {
        @include rem-fallback(padding-right, 10);
    }
}

.forum-answer {
    .answer-avatar {
        @include rem-fallback(margin-right, 10);
    }
    .answer-card {
        background-color: $card-cap-bg;
        border: unset;
        @include rem-fallback(padding, 10);
        width: 100%;
    }
}

.forum-form {
    background-color: $card-cap-bg;
    // @include rem-fallback(border-radius, 22);
    @include rem-fallback(max-width, 820);
    @include rem-fallback(padding, 10);
    width: 100%;
}


.file-icon {
    @include rem-fallback(width, 64);
    @include rem-fallback(height, 64);
}

@media (max-width: 550px) {
    .forum-card {
        @include rem-fallback(margin-left, -21);
        @include rem-fallback(margin-right, -21);
        border-radius: unset;
        .forum-text-wrapper {
            @include rem-fallback(margin-left, -40);
        }
    }
}

.forum-table {
    th {
        border-top: none;
    }
}

:global(video) {
    max-width: 100%;
    &:focus{
        outline: 0.2rem solid var(--primary, $primary);
    }
}

:global(audio) {
    max-width: 100%;
    &:focus{
        outline: 0.2rem solid var(--primary, $primary);
    }
}

/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .forum-table {
        td {
            .thread-writer-avatar {
                display: none;
            }
            .thread-generic-avatar {
                display: none;
            }
        }
    }
}
