@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";
@import "assets/scss/lnvariables.scss";

@import "assets/scss/core/mixins/_breakpoints.scss";

.cardWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.card {

  @include rem-fallback(margin-right, 25);
  @include rem-fallback(margin-bottom, 20);
  width: 18rem;
  height: 17.9rem;

  @include media-breakpoint-down(xs) {
    width: 16rem;
    @include rem-fallback(margin-right, 15);
  }
}



.card-title {
  font-size: 1em;
  font-weight: 600;

  .nameDiv {
    height: 4rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;

  }

}
.img-wrapper {
  text-align: center;
  position: relative;

  .lecture-img {
    border-radius: unset;
    max-height: 8rem;
  }
}


.printButtonWrap {
  width: 3rem;
  position: absolute;
  top: -1rem;
  right: -1rem;

  .printBtn {
    background: $gray-100;

    border-radius: 50% !important;
    border: solid 0.15rem $gray-600;
    height: 3rem;
    width: 3rem;
    padding: .4rem 0 0 .35rem ;
  }

}

.screenThumbContainer {
  min-height: 120px;
}

.card-footer {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  background-color: transparent;
  justify-content: space-between;
  span {
    white-space: nowrap;
    .footer-icon {
      @include rem-fallback(height, 13);
    }
  }
}

@media (max-width: 479px) {
  .card-title {
    flex-direction: row !important;
    justify-content: space-between !important;
  }
}

.lecture {
  background-color: $gray-600;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: $lecture-z-index;
  overflow: hidden;

  .screen {
    width: 100%;
    height: calc(100% - 8.5rem);
    max-height: 100%;
    * {
      max-height: 100%;
    }
    position: relative;


    .bigPlayButton {
      position: absolute;
      z-index: 10;
      width: 20rem;
      height: 20rem;
      top: calc(50% - 10rem);
      left: calc(50% - 10rem);
      //left: calc( 50% - 2rem);
      background-color: $ln-focus-color;
      border-radius: 10rem;
      img {
        position: absolute;
        width: 10rem;
        height: 10rem;
        top: calc(50% - 5rem);
        left: calc(50% - 4rem);
      }
    }

    // .screenImg {
    //   max-height: 100%;
    // }

    // .screenImgWithHtml {
    //   max-height: calc(100% - 7rem);

    // }

    .htmlForScreen {
      // min-width: 30rem;
      // max-width: 70rem;
      text-align: center;
      min-height: 7rem;
      width: 100%;
      overflow: hidden;
      background-color: $white;

      & > div {
        display: inline-block;
        width: 100%;
        min-width: 30rem;
        padding: 1rem 2rem 1rem 7rem;
        text-align: left;
        overflow-y: auto;
      }
    }

    .imgSoundPlayer {
      height: 100%;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 100%;

      &.withTranscript {
        grid-template-columns: auto 30rem;
      }
    }
  }

  :global(video) {
    &:focus {
      outline: none;
    }
  }

  :global(.screenTranscript *){
    max-height: initial;
  }

  &.fullScreen {
    .videoWrap,
    .mediaWrap,
    .screen {
      width: 100% !important;
      height: 100% !important;
    }

    .controls {
      background-color: #ffffff55;
    }
  }

  .progressThumbnail {
    position: absolute;
    top: -80px;
    overflow: hidden;
    height: 32px;
    border: 1px solid $white;
    img {
      position: absolute;
      width: auto;
      max-width: none;
      height: auto;
    }
  }

  .toggleFullScreen {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: $lecture-z-index + 1;
  }

  .icon_with_margin {
    height: 6rem;
    width: 6rem;
    opacity: 1;
    div {
      height: 4rem;
      width: 4rem;
      background-color: var(--primary, $primary);
      border-radius: 2rem;
    }
  }

  .transcript_Icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 10;
  }

  .dTranscript {
    cursor: default;
    max-width: 40rem;
    min-width: 25rem;
    height: calc(100% - 2rem);
    :global(.card-body) {
      overflow: auto;
    }
    :global(.card-body) {
      cursor: text;
    }
  }

  .dImgSoundGrid {
    display: grid;
    grid-template-columns: auto 1fr auto;
  }

  .dCenterGrid {
    display: grid;
    place-items: center;
  }

  .dImgTranscriptGrid {
    display: grid;
    grid-template-rows: 1fr minmax(5rem, auto);
  }

  .simple_progress {
    border: 1px solid $gray-200;
    background-color: #ffffff33;
    height: 0.6rem;
    border-radius: 0.3rem;
    position: absolute;
    z-index: 110;
    overflow: hidden;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    :global(.progress-bar) {
      height: 100%;
      background-color: $status-passed-color;
      border-radius: 0.3rem;
      transition: width 0.1s linear;
    }
  }

  .controls {
    position: absolute;
    bottom: 0;
    height: 8.5rem;
    width: 100%;
    background-color: $white;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &.fadeOut {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear 500ms, opacity 500ms;
    }
    &.fadeIn {
      visibility: visible;
      opacity: 1;
      transition: visibility 500s linear 0s, opacity 500ms;
    }

    .progress {
      border: 1px solid $gray-400;
      background-color: $white;
      height: 1.2rem;
      border-radius: 0.6rem;
      position: relative;
      overflow:visible;
      width: 100%;
      :global(.progress-bar) {
        height: 100%;
        background-color: $status-passed-color;
        border-radius: 0.6rem;
        transition: width 0.1s linear;
      }

      .cuepoint{
        position: absolute;
        height: calc( 100% + 0.5rem) ;
        width: auto !important;
        top: -0.8rem;
      }
    }

    .segments {
      position: absolute;
      //border: 1px solid $gray-400;
      background-color: transparent;
      height: 0.3rem;
      width: 100%;
      bottom: 0;
      > div {
        //transition: all 0.1s linear;
        height: 0.3rem;
        transition: all 0.1s linear;
        position: absolute;
        background-color: var(--primary, $primary);
        border-radius: 0.15rem;
      }
    }

    .buttonsbar {
      width: 100%;
      max-width: 60rem;
      height: 5rem;
      background-color: $gray-600;
      border-radius: 2.5rem;
      color: $white;

      :global(.icon) {
        height: 2.8rem;
        width: 2.8rem;
        margin: 0 0.5rem;
      }

      .autoNext {
        fill: $red_1;
        fill-rule: evenodd;
        stroke: #56585b;
        stroke-width: 1px;
        &.green {
          fill: var(--primary, $primary);
          stroke: var(--primary, $primary);
        }
      }

      :global(div.dropdown) {
        line-height: 0.1rem;
      }

      :global(.dropdown-menu) {
        border: none;
        border-radius: 2rem;
        background-color: $gray-600;
        padding: 1.2rem;

        width: auto;

        box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.55);
        button {
          color: $white;
        }

        &.volume {
          height: 4rem;
          width: 25rem;
        }
      }

      :global(.btn) {
        &:disabled {
          opacity: 0.25;
        }
      }

      .numbers {
        width: 3rem;
      }

      .speeds {
        height: 5.5rem;
        width: 5.5rem;
        border-radius: 2.75rem;
        padding: 0;
      }

      .speedButton {
        background-color: var(--primary, $primary);
      }

      .divider {
        background-color: $white;
        width: 0.1rem;
        height: 2.2rem;
        overflow: hidden;
        margin: 0 1rem 0 1rem;
      }
    }
  }
}

.roundButton {
  height: 4rem;
  width: 4rem;
  background-color: var(--primary, $primary);
  border-radius: 2rem;
  :global(.icon) {
    height: 2.1rem !important;
    width: 2.1rem !important;
    margin: 0 !important;
    position: relative;
    left: 1.2rem !important;
  }
  &:hover {
    opacity: 0.8;
    background-color: var(--primary-darker, $primary-darker);
  }
  &:disabled{
    background-color: var(--grey400, $gray-400);
  }
}

.hideControlsButton {
  top: -3.5rem;
  position: absolute;
  left: -0.5rem;
  img {
    width: 4rem !important;
    height: auto;
  }
}

.lecturePrint {
  position: absolute;
  padding: 1rem;
  top: 10rem;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  background-color: $white;

  hr {
    margin-bottom: 3rem;
  }

  @media print {
    .handoutPage {
      page-break-inside: avoid;
    }
    & {
      position: relative !important;
      overflow: visible !important ;
      left: auto;
      right: auto;
      bottom: auto;
      top: auto;
      img {
        &.type2 {
          height: 15cm;
          max-width: 100%;
        }
      }
    }
  }
}

.showControlsButton {
  position: absolute;
  bottom: 2rem;
  left: 1rem;
  z-index: 2;
}
