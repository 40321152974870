//Tranform
@mixin translate($transformX, $transformY) {
	-moz-transform: translate($transformX, $transformY);
	-o-transform: translate($transformX, $transformY);
	-ms-transform: translate($transformX, $transformY);
	-webkit-transform: translate($transformX, $transformY);
	transform: translate($transformX, $transformY);
}

@mixin skew($skewx,$skewy) {
	-moz-transform: skew($skewx,$skewy);
	-o-transform: skew($skewx,$skewy);
	-ms-transform: skew($skewx,$skewy);
	-webkit-transform: skew($skewx,$skewy);
	transform: skew($skewx,$skewy);
}

@mixin rotate($rotate) {
	-moz-transform: rotate($rotate);
	-o-transform: rotate($rotate);
	-ms-transform: rotate($rotate);
	-webkit-transform: rotate($rotate);
	transform: rotate($rotate);
}

@mixin translateY($transforms) {
	-moz-transform: translateY($transforms);
	-o-transform: translateY($transforms);
	-ms-transform: translateY($transforms);
	-webkit-transform: translateY($transforms);
	transform: translateY($transforms);
}

@mixin translateX($transforms) {
	-moz-transform: translateX($transforms);
	-o-transform: translateX($transforms);
	-ms-transform: translateX($transforms);
	-webkit-transform: translateX($transforms);
	transform: translateX($transforms);
}
@mixin translateZ($transforms) {
	-moz-transform: translateZ($transforms);
	-o-transform: translateZ($transforms);
	-ms-transform: translateZ($transforms);
	-webkit-transform: translateZ($transforms);
	transform: translateZ($transforms);
}
@mixin scale($transforms) {
	-moz-transform: scale($transforms);
	-o-transform: scale($transforms);
	-ms-transform: scale($transforms);
	-webkit-transform: scale($transforms);
	transform: scale($transforms);
}
@mixin transition($transition) {
	transition: $transition;
	-o-transition: $transition;
	-ms-transition: $transition;
	-webkit-transition: $transition;
	transition: $transition;
}

@mixin grayscale($filter){
	filter: grayscale($filter);
	-webkit-filter: grayscale($filter);
	-moz-filter: grayscale($filter);
	-o-filter: grayscale($filter);
	-ms-filter: grayscale($filter);
}

@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

//placeholder
@mixin placeholder {
	@include optional-at-root('::-webkit-input-placeholder') {
		@content;
	}

	@include optional-at-root(':-moz-placeholder') {
		@content;
	}

	@include optional-at-root('::-moz-placeholder') {
		@content;
	}

	@include optional-at-root(':-ms-input-placeholder') {
		@content;
	}
}

//Border Radius
@mixin border-radius($radius) {
	border-top-right-radius: $radius;
	border-top-left-radius: $radius;
	border-bottom-left-radius: $radius;
	border-bottom-right-radius: $radius;
}

@function strip-unit($num) {
	@return $num * 0.1;
}

@mixin rem-fallback($property, $values...) {
	$max: length($values);
	$pxValues: '';
	$remValues: '';

	@for $i from 1 through $max {
		$value: strip-unit(nth($values, $i));
		$pxValues: #{$pxValues + $value*16}px;

		@if $i < $max {
			$pxValues: #{$pxValues + " "};
		}
	} 

	@for $i from 1 through $max {
		$value: strip-unit(nth($values, $i));
		$remValues: #{$remValues + $value}rem;

		@if $i < $max {
			$remValues: #{$remValues + " "};
		}
	} 

	//#{$property}: $pxValues; 
	#{$property}: $remValues; 
}

$base_line: 10;

@mixin rem($property, $values,  $important:"" ) {
	// Placeholder variables
	$shorthand_px : "";
	$shorthand_rem: "";

	// Parameter $values might be a list of elements
	@each $value in $values {

		// Current value is a valid number and greater than 0
		@if $value !=auto and $value !=0 and $value !=inherit {
			// Add 'px' and 'rm' to the current value and store in
			// placeholder variables
			$shorthand_px : #{ $shorthand_px + " " + $value * $base_line + px };
			$shorthand_rem: #{ $shorthand_rem + " " + $value + rem };
		}

		// Current value is 'auto' or 0
		@else {
			// Add only 'auto' or 0 to the placeholder variables
			$shorthand_px : #{ $shorthand_px + " " + $value };
			$shorthand_rem: #{ $shorthand_rem + " " + $value };
		}
	}

	@if $important== !important {
		$shorthand_px : #{ $shorthand_px + " !important" };
		$shorthand_rem: #{ $shorthand_rem + " !important" };
	}

	// Output the CSS property in pixels and rems
	#{$property}:$shorthand_px;
	#{$property}:$shorthand_rem;
}
