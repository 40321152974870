.ln-card {
  @include rem-fallback(max-width, 500);
  @include rem-fallback(min-width, 300);
  border: none;
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;



  &.card-vertical-fixed {
    @include rem-fallback(max-width, 145);
    @include rem-fallback(min-width, 145);
    @include rem-fallback(width, 145);
    .card-img-top {
      @include rem-fallback(height, 145);
      align-items: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
      width: 100%;
      img {
        height: 100%;
      }
    }
    .card-img-top-horzontal {
      @include rem-fallback(min-height, 100);
      @include rem-fallback(max-height, 100);
      align-items: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
      img {
        max-width: 100%;
      }
    }
  }

  .card-img-wrapper {
    border: solid 1px rgba(0, 0, 0, 0.125);
    border-radius: 0;
    padding: unset;
    position: relative;
    img {
      border-radius: unset;
      max-height: 100%;
      max-width: 100%;
      width: auto;
    }
  }

  .card-body {
    padding: 0 1.25rem;
    .card-btn-group {
      width: 100%;
      .card-btn-group-btn {
        background-color: rgb(246, 246, 246);
        border-color: rgb(228, 228, 228);
        border-radius: unset;
        border-top: none;
        padding: 0;
        .icon {
          object-fit: fill;
        }
      }
      .card-btn-group-btn::active {
        border-color: rgb(211, 211, 211);
      }
    }
  }

  .card-footer {
    padding: 0.75rem 1.25rem;
  }

  .group-card-header {
    .toggle-icon {
      position: absolute;
      @include rem-fallback(top, 10);
      @include rem-fallback(right, 10);
      @include rem-fallback(width, 20);
    }
  }
}

// @media (max-width: 479px) {
//   .ln-card {
//     min-width: 100%;
//   }

//   .card-box.big-card-box.card.ln-card {
//     border-top-right-radius: 10px;
//     border-top-left-radius: 10px;
//     border-bottom-left-radius: 10px;
//     border-bottom-right-radius: 10px;
//   }
// }
