.loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--white, $white);
    z-index: 10000;

    .loaderLogo {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: -120px;
        margin: auto;
        z-index: 3;
        width: 182px;
    }
}

.ldsRipple {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 3;
    width: 64px;
    height: 64px;
}

.ldsRipple h2 {
    width: 60rem;
    text-align: center;
    position: absolute;
    left: -26rem;
    top: -3rem;
}

.ldsRipple div {
    position: absolute;
    border: 4px solid var(--primary, $primary);
    opacity: 1;
    border-radius: 50%;
    animation: ldsRipple 1.5s ease infinite;
}

/* cubic-bezier(0, 0.2, 0.8, 1) */

.ldsRipple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes ldsRipple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
