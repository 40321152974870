@import "../../../assets/scss/core/functions";
@import "../../../assets/scss/core/variables";
@import "../../../assets/scss/base/colors.scss";

@import "../../../assets/scss/base/mixins.scss";

.theme {
    border-radius: 10px;
    margin-bottom: 1rem;
    button{
        color: var(--body-text-color, #000);
    }

    .header {
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        cursor: pointer;
        background-color: transparent;
        font-size: 1.6rem;
        color: #56585b;
        border: none;
        position: relative;

        .themename {
            text-align: left;
        }

        img {
            width: 2.2rem;
            height: 1.73rem;

            &.indicator {
                width: 1.2rem;
                height: 1.2rem;
                -o-transition: all 0.4s;
                -ms-transition: all 0.4s;
                -webkit-transition: all 0.4s;
                transition: all 0.4s;
            }

            &.down {
                transform: rotate(90deg);
            }
        }
    }

    .parts {
        font-size: 1.4rem;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        padding: 0;

        img {
            width: 1.3rem;
            margin-right: 10px;
        }

        .part {
            display: flex;
            padding: 0.5rem 0.4rem 0.5rem 3rem;
            line-height: 2rem;
            font-weight: 400;
            position: relative;
            align-items: baseline;
            cursor: pointer;
            border-radius: 0;

            &:hover {
                border-radius: 0;
            }

            .partname {
                margin-top: 0;
                line-height: 1.8rem;
                text-align: left;
            }

            .status-icon {
                width: 1.5rem;
                height: 1.5rem;
                position: absolute;
                left: 0.5rem;
            }
        }
    }
}
