footer {
  text-align: right;
  padding: 15px 31px;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
  .copyright-text {
    font-size: 14px;
    line-height: 16px;
    color: $grey_5;
    font-family: var(--primary, $primary);
    font-weight: 400;
  }
}
