@mixin badge-variant($bg) {
  color: color-yiq($bg) !important;
  background-color: $bg !important;

  @at-root a#{&} {
    @include hover-focus() {
      color: color-yiq($bg) !important;
      background-color: darken($bg, 10%) !important;
    }

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: 0 0 0 $badge-focus-width rgba($bg, .5);
    }
  }
}
