@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";
@import "assets/scss/base/colors.scss";

.firstRow {
    border-top: 1px solid $grey_7;
    td {
        border: unset;
    }
}
.studentNotes{
    h3{
        font-size: 1.6rem;
        font-weight: bold;
    }
}
.subRow {
    border: unset;
    td {
        border: unset;
        border-top: 1px solid $grey_7;
    }
    td:nth-child(1) {
        border-top: unset;

    }
}

.graphListstyle{
    border: none;
}
.chartStyle{
    width: 10rem;
}

.testCell{
    margin-left: 4rem;
    img{
        margin-left: -4rem;
    }
}


.courseTable {
    width: 36rem;

    td {
        border: 0.2rem solid $gray-300;
    }

    thead {
        td {
            border: 0.2rem solid $gray-300;
            position: sticky;
            top: 1px;
            background-color: $white;
            div {
                width: 20rem;
                height: 5.5rem;
                text-align: center;

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    td {
        div {
            width: 20rem;
            height: 2rem;
        }
    }
}
