@include  media-breakpoint-down(sm) {
    .dropdown-item {
        font-size: 2rem;
        line-height: 3rem;
    }
}

.dropdown-menu.bubble:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: $white transparent;
    border-width: 20px 10px 0;
    bottom: -20px;
    left: 50%;
    margin-left: -10px;
}

.lectureBubble{
    top: -100px;
    div{
        max-width: 40rem;
    }
}