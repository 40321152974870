@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";

.part-listing {
  a {
    color: unset;
    &:hover {
      text-decoration: none;
    }
  }
  position: relative;

  width: 22rem;
  height: 25rem;

  .part-listing-card {
    border-radius: unset;
    height: 100%;
    overflow: hidden;

    &:hover {
      img {
        filter: contrast(1);
      }

      .imageDummy {
        background: var(--primary, $primary);
        background: radial-gradient(ellipse at top, var(--warning, $warning), transparent),
          radial-gradient(ellipse at bottom, var(--primary, $primary), transparent);
      }
    }

    
  }

  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .card-image {
    @include rem-fallback(height, 130);
    overflow-y: hidden;
    img {
      max-height: 100%;
    }
  }

  img {
    -webkit-filter: contrast(120%) grayscale(60%);

    filter: contrast(120%) grayscale(60%);

    &:after {
      content: " " attr(alt);

      font-size: 16px;
      color: rgb(100, 100, 100);

      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      @include rem-fallback(height, 100);
      background-color: #fff;
    }
  }
  .imageDummy {
    background: var(--grey300, $gray-300);
    background: radial-gradient(ellipse at top, var(--grey300, $gray-300), transparent),
      radial-gradient(ellipse at bottom, var(--grey600, $gray-600), transparent);
  }
}
