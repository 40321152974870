@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";
@import "assets/scss/base/colors.scss";

.detail-label {
    font-weight: 700;
}

.file-icon {
    @include rem-fallback(width, 44);
    @include rem-fallback(height, 44);
}

.mail-form {
    @include rem-fallback(max-width, 820);
    width: 100%;
}

.receiver-icon {
    padding-bottom: 6px;
}

.spinner-icon {
    margin: -9px -6px;
    @include rem-fallback(height, 34);
    @include rem-fallback(width, 34);
}

.sticky-footer {
    background-color: white;
    bottom: 0;
    position: sticky;
}

@media only screen and (max-width: 767px) {
    .email-single-explain {
        display: none;
    }
}
