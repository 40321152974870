.MuiDrawer-paper {
  position: inherit !important;
  display: block !important;
}
.menu-sidebar {
  .list-item {
    margin-bottom: 10px !important;
  }
  .MuiCollapse-wrapper {
    .MuiButtonBase-root {
      img {
        @include rem-fallback(width,13);
      }
    }
  }
  .MuiList-padding{
    padding-top: 0px;
  }
}
.menubar-list {
  .list-item {
    .MuiListItemText-inset {
      @include rem-fallback(font-size,18);
      @include rem-fallback(line-height,22);
      font-weight: 400;
      display: flex;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      @include rem-fallback(padding,0,40,0,15);
      position: relative;
      margin: 0;
      .MuiTypography-body1 {
        @include rem-fallback(font-size,16);
        color: $secondary;
      }
    }
    &.MuiListItem-button {
      &:hover {
        background-color: transparent !important;
      }
    }
  }
  img{
    @include rem-fallback(width,22);
  }
  
}
.MuiListItem-button {
  &:hover {
    background-color: transparent !important;
  }
}
.menubar-list
  .list-item
  .MuiListItemText-inset
  .MuiTypography-body1 {
  font-weight: bold;
}
.multi-list {
  &.MuiListItem-root {
    display: flex;
    @include rem-fallback(padding,0,35,0,10);
    @include rem-fallback(font-size,16);
    @include rem-fallback(line-height,20);
    font-weight: 400;
    @include rem-fallback(margin-top,4);
    position: relative;
    align-items: baseline;
    .MuiListItemText-inset {
      @include rem-fallback(padding-left,11);
      margin-top: 0;
    }
    .MuiTypography-body1 {
      @include rem-fallback(font-size,14);
    }
  }
}
.has-child {
  .right-open-arrow {
    transform: rotate(90deg);
  }
}
.dash-panel {
  margin: 0;
}
