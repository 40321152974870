@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";
@import "assets/scss/base/colors.scss";

/* Here be css for test statuses */

.approved {
    background-color: $status-approved-color;
}

.approvedbyteacher,
.approvedbyteacherbutcanbebetter,
.approvedwithexcellence,
.approved-excellence {
    background-color: $status-approved-byteacher-color;
}

.failed{
    background-color: $status-failed-color;
}

.followupneeded {
    background-color: $status-followup-color;
}


.incomplete,
.notset {
    background-color: $status-notset-color;
}

.passed,
.recievedbyteacher,
.underreview {
    background-color: $status-passed-color;
}

.grade-approved {
    background-color: $grade-approved-color;
}

.grade-approved-excellence {
    background-color: $grade-approved-excellence-color;
}

.grade-failed {
    background-color: $grade-failed-color;
}

.grade-notset {
    background-color: $grade-notset-color;
}
