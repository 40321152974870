@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "../../../../assets/scss/base/mixins.scss";
@import "../../../../assets/scss/lnvariables.scss";

.qatest{
    textarea{
        @include rem-fallback(min-height, 4 * $normal-line-height);
    }

}