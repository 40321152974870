@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";

.btn-group-wrapper {
  text-align: right;
  margin: 1rem;
}

.cardWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.file-icon {
  height: 5rem;
  width: auto;
}

.listCard{
  width: 16rem;
  //border:none;
}

.fileInfo{
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp {
  max-height: 4rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
