@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";

.cardWrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }

.link-icon {
    @include rem-fallback(height, 60);
    @include rem-fallback(margin, 20);
  }

  
.listCard{
  width: 16rem;
  min-height: 12rem;
}

.linkInfo{
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}