
.progress-block{
	h3{
		@include rem-fallback(margin-bottom,12);
	}
}
.progress-listing{
	display:flex;
	align-items:center;
	flex-wrap:wrap;
	@include rem-fallback(margin,0,-15);
	@include rem-fallback(padding,20,0,25);
	@include media-breakpoint-down(sm) {
		@include rem-fallback(padding,15,0);
	}
	li{
		display:flex;
		align-items:center;
		justify-content:space-between;
		@include rem-fallback(padding,0,15,10);
		@include media-breakpoint-down(xs) {
			width:100%;
			justify-content:flex-start;
		}
		span{
			display:inline-block;
			@include rem-fallback(font-size, 16);
			@include rem-fallback(line-height, 18);
			color: $secondary;
			font-weight: 400;
			@include rem-fallback(margin-left, 6);

		}
		&.text{
			span{
				@include rem-fallback(margin-left,35);
			}
		}
	}
}
.progress-detail-wrapper{
	border-top:1px solid $grey_8;
}
.progress-detail{
	display:flex;
	justify-content:space-between;
	flex-wrap:wrap;
		// @include rem-fallback(padding-top,15);
		border-bottom:1px solid $grey_8;
		.inner-detail{
			border-bottom:1px solid $grey_8;
			display:flex;
			justify-content:space-between;
			align-items:flex-start;
			flex-wrap:wrap;
			@include rem-fallback(padding,13,0);
			&:last-child{
				border:0;
			}
			.pro-detail-left{
				width:50%;
			}
		}
		.progress-left{
			width:14%;
			@include rem-fallback(padding,10,0);
			@include media-breakpoint-down(lg){
				width:20%;
			}
			@include media-breakpoint-down(sm){
				width:100%;
				// @include rem-fallback(margin-bottom,10);
			}
			h4{
				@include rem-fallback(font-size, 16);
				@include rem-fallback(line-height,20);
				@include rem-fallback(margin-bottom,3);
				color: $secondary;
				font-weight: 400;
			}
		}
		.progress-right{
			width:86%;
			@include media-breakpoint-down(lg){
				width:80%;
			}
			@include media-breakpoint-down(sm) {
				width:100%;
			}
			.left-detail{
				@include rem-fallback(padding-right,15);
				width:44%;
				@include media-breakpoint-down(xs) {
					width:100%;
					@include rem-fallback(margin-bottom,15);

				}
				p{
					@include rem-fallback(font-size, 16);
					@include rem-fallback(line-height,20);
					color: $secondary;
					font-weight: 400;
					@include rem-fallback(max-width,216);
					width:100%;
				}
			}
		}
		.progress-listing{
			margin:0;
			padding:0;
			width:56%;
			@include media-breakpoint-down(xs) {
				width:100%;
			}
			li{
				width:100%;
				justify-content:flex-start;
				align-items:flex-start;
				@include rem-fallback(margin,0,0,8);
				padding:0;
				span{
					@include rem-fallback(line-height,24);
					// @include rem-fallback(max-width,200);
					// width:100%;
				}
				&:last-child{
					margin:0;
				}
			}
		}
		
	}


	.progress-status{
		@include rem-fallback(height,25);
		@include rem-fallback(width,25);
		border-radius:50%;
		background-color:var(--primary, $primary); 
		display:flex;
		align-items:center;
		justify-content:center;
		position:relative;
		@include rem-fallback(padding,5);
		&.half{
			&:after{
				content:"";
				position: absolute;
				@include rem-fallback(height,10);
				@include rem-fallback(width,15);
				@include rem-fallback(left,13);
				@include rem-fallback(top,16);
				transform: rotate(-50deg);
				border-radius:45%;
				background-color:$white;

			}
		}
		img{
			height:auto;
			width:auto;
		}
		&.grey{
			background-color:$grey-status;
		}
		&.blue{
			background-color:$blue_1;
		}
		&.yellow{
			background-color:$yellow_1;
		}
		&.dark-blue{
			background-color:$dark_blue_1;
		}
		&.lavender{
			background-color:$lavender;
		}
		&.green{
			background-color:var(--primary, $primary);
		}
		&.red{
			background-color:$red_1;
		}


	}

	.rating{
		@include rem-fallback(padding,2,9);
		background-color:$blue_1;
		display:inline-flex;
		align-items:center;
		justify-content:center;
		@include rem-fallback(height,20);
		position:relative;
		@include rem-fallback(border-radius,25);

		span{
			@include rem-fallback(font-size,14);
			@include rem-fallback(line-height,18);
			@include rem-fallback(padding-bottom,1);
			border-radius:50%;
			color:$white;
			font-weight: 400;
		}
		i{
			display:inline-block;
			@include rem-fallback(width,15);
			@include rem-fallback(height,13);
			@include rem-fallback(margin-right,2);
			@include rem-fallback(margin-bottom,1);
			img{
				display:block;
			}
		}
	}

	.myprogress-chart {
		width: 60%;
		margin: 0 auto;
		margin-bottom: 20px;
	}
