@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "../../assets/scss/base/mixins.scss";
@import "../../assets/scss/base/colors.scss";
@import "../../assets/scss/base/mixins.scss";

.loginPage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  min-height: 100%;
  transition: all 0.3s;
  position: relative;

  .infoButton {
    background-color: $white;
    padding: 1rem;
    margin: 0.5rem;
    position: absolute;
    right: 0;
    top: 0;
    @media (min-width: 767px) {
      display: none;
    }
  }

  .welcomeBlock {
    overflow-x: hidden;
    width: 39rem;
    background-color: var(--primary, $primary);
    height: 100vh;
    @include rem-fallback(padding, 50, 40);
    color: $white;

    @media (max-width: 991px) {
      width: 30rem;
      padding: 3rem;
    }

    @media (max-width: 767px) {
      width: 39rem;
      position: absolute;
      right: 0;
      top: 0;
      display: none;
    }

    h1,
    h2,
    h3,
    h4 {
      color: $white;
    }

    h1 {
      position: relative;
      margin: 0 0 20px;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $white;
        top: 55%;
        left: 100%;
      }
    }

    a {
      color: $white;
    }

    .closeInfoButton {
      background-color: $white;
      padding: 1rem;
      margin: 0.5rem;
      position: absolute;
      right: 0;
      top: 0;
      @media (min-width: 767px) {
        display: none;
      }
    }
  }

  .bannerBlock {
    height: 100vh !important;
    width: calc(100% - 390px);

    background-size: cover !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: left;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: left;
    -ms-flex-pack: center;
    justify-content: left;
    padding-left: 3rem;

    @media (max-width: 991px) {
      width: calc(100% - 30rem);
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    .loginBox {
      width: 100%;
      max-width: 35rem;
      @include rem-fallback(padding, 8, 0, 50, 0);
      position: relative;
      background: $white;
      @include rem-fallback(min-height, 461);
      @include rem-fallback(border-radius, 4);

      .loginForm {
        @include rem-fallback(padding, 0, 20);
      }

      .forgotPasswdLink {
        float: right;
        @include rem-fallback(font-size, 18);
        color: var(--grey600, #777777);
        @include rem-fallback(line-height, 24);
        margin-bottom: 0;

        &:hover {
          color: #5faa45;
        }
      }

      .logoImage {
        width: 206px;
        height: 37px;
        margin-right: auto;
        margin-left: auto;
        @include rem-fallback(margin-bottom, 45);
        display: block;
      }

      .pdfLink {
        text-align: center;

        &:hover {
          color: var(--primary, $primary);
        }

        img {
          @include rem-fallback(width, 18);
          height: auto;
        }
      }

      .backArrow {
        @include rem-fallback(width, 24);
        @include rem-fallback(height, 14);
        cursor: pointer;
      }

      .loginBoxHeader {
        text-align: right;
        @include rem-fallback(margin, 0, 0, 17, 0);
        @include rem-fallback(padding, 0, 25);

        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;

        .flag {
          background: none;
          border: none;
          @include rem-fallback(width, 62);

          &::after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 0.4em;
            vertical-align: 0.03em;
            content: "";
            border-top: 0.6em solid #606060;
            border-right: 0.6em solid transparent;
            border-bottom: 0;
            border-left: 0.6em solid transparent;
          }

          img {
            max-width: 25px;
          }
        }
      }

      .flagItem {
        img {
          max-width: 25px;
        }
      }

      .dropdownMenu {
        width: 150px;
      }
    }
  }
}

.newPassword {
  height: 100%;
  width: 100%;
  background-size: cover !important;

  .forms{
    width : #{'min(100%, 40rem)'};
  }
  
}

.register{
  max-width: 120ch;
  :global(.form-group){
    width: 100%;
  }
}

.logoRegister {
  width: 130px;
}
