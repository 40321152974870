@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/colors.scss";
@import "assets/scss/core/_functions.scss";
@import "assets/scss/core/_mixins.scss";



.searchMenu {

    position: fixed;
    max-width: 50rem;
    
    @include media-breakpoint-down(sm) {
        max-width: 100%;
    }

    top: 1rem;
    bottom: 0.2rem;
    right: 0;
    background-color: $white;
    z-index: 2;
    border-radius: 1rem;


    .searchform {
        padding: 1.5rem;

        input {
            width: 30rem;
            border-top-left-radius: 1.8rem;
            border-bottom-left-radius: 1.8rem;
        }
    }

    .results{
        position: absolute;
        bottom: 0;
        top: 10rem;
    }
}
