@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";
@import "assets/scss/base/colors.scss";

.errorMess{
    font-size: 80%;
    color: $danger;
    position: absolute;
}

.errorInput{
    border-color: $danger;
}

.nav {
    background-color: unset;
    border: unset;
    border-bottom: 1px solid $gray-primary;
}

.headerTimeLabel {
    @include rem-fallback(margin-left, -8);
}

.thDate {
    @include rem-fallback(max-width, 120);
    @include rem-fallback(min-width, 120);
    @include rem-fallback(width, 120);
}

.thTickWrapper {
    height: 4px;
    margin: -3px;
    position: relative;
    .thTick {
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: $gray-primary;
        &.first {
            left: 0;
        }
        &.middle {
            left: 33.333%;
        }
        &.last {
            left: 66.666%;
        }
    }
}

.tdCenter {
    vertical-align: middle !important;
}

.firstRow {
    border-top: 1px solid $gray-primary;
    td {
        border: unset;
    }
}

.subRow {
    border: unset;
    td {
        border: unset;
    }
}

.fakeDimmedBox{
    background-color: $input-disabled-bg;
    border-radius: $input-border-radius;
    border: $input-border-width solid $input-border-color;
    padding: $input-padding-y $input-padding-x;
}

.onlineWrapper {
    position: relative;
    width: 100%;
    height: 1rem;
    @include rem-fallback(height, 10);
    .onlineBar {
        background-color: var(--primary, $primary);
        border-radius: 4px;
        height: 100%;
        position: absolute;
        border-right: 2px solid $gray-600;
        .onlineBarText{
            display: none;
        }
        &:hover{
            background-color: var(--link-color, $link-color);
            height: 150%;
            .onlineBarText{
                display: block;
                position: absolute;
                top: 1.8rem;
                background-color: $white;
                z-index: 2;
            }
        }
    }
}


.progress {
    @include rem-fallback(height, 6);
    @include rem-fallback(margin-bottom, 10);
    @include rem-fallback(margin-top, 8);
}

.legendDisplay {
    @include rem-fallback(border-radius, 3);
    @include rem-fallback(height, 6);
    @include rem-fallback(margin-bottom, 10);
    @include rem-fallback(margin-right, 5);
    @include rem-fallback(margin-top, 8);
    @include rem-fallback(width, 20);
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    .thTickWrapper {
        .thTick {
            &.middle {
                display: none;
            }
            &.last {
                display: none;
            }
        }
    }

    .DatePickerWrapper {
        @include rem-fallback(font-size, 12);
    }

    .tdContent {
        display: flex !important;
        .coursePartValue {
            @include rem-fallback(font-size, 12);
            font-weight: 700;
        }
    }

    
    .firstRow {
        .tdTheme {
            label {
                @include rem-fallback(font-size, 16);
            }
        }
    }

    .onlineDay {
        @include rem-fallback(font-size, 12);
    }

    .subRow {
        background-color: #f3ffef;
        td.tdTheme {
            display: none !important;
        }
    }
}
