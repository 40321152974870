@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";

.btn-small {
  @include rem-fallback(height, 36);
}

.dimmed{
  opacity: 0.6;
}

.online-status {
  margin-left: 2rem;
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    margin-left: -2rem;
    top: .3rem;
    @include rem-fallback(width, 15);
    @include rem-fallback(height, 15);
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #ccc;
    margin-right: 10px;
  }
}
.online::before {
  background-color: #5faa45;
}

.main-teacher {
  background-color: #5faa45;
  @include rem-fallback(border-bottom-left-radius, 36);
  @include rem-fallback(border-top-right-radius, 8);
  @include rem-fallback(height, 36);
  position: absolute;
  right: 0;
  top: 0;
  @include rem-fallback(width, 36);
}

@media print {
  .online-status::before {
    display: none;
  }
}

.cloud{
  padding: 0.4rem 0 0 1.6rem;
  z-index: 1;

  background-color: #5faa45;
  @include rem-fallback(border-bottom-left-radius, 46);
  @include rem-fallback(border-top-right-radius, 8);
  @include rem-fallback(height, 46);
  position: absolute;
  right: 0;
  top: 0;
  @include rem-fallback(width, 46);


}