@import "./base/colors.scss";

$input-line-height: 1.2;

$max-container-width : 115rem;

$margin-xs-vertical    : 5px;
$margin-small-vertical : 10px;
$margin-medium-vertical: 20px;
$margin-large-vertical : 40px;

$margin-medium-horizontal: 10px;

$padding-base-vertical  : 6px;
$padding-base-horizontal: 12px;

$padding-large-vertical  : 10px;
$padding-large-horizontal: 16px;

$padding-small-vertical  : 5px;
$padding-small-horizontal: 10px;

$padding-xs-vertical: 1px;
$padding-xs-horizontal: 5px;

$input-font-size: 1.6rem;  // scale up from default 1rem since we use "font-size : 62.5%;" in <HTML> css

$normal-line-height : 20;
$custom-control-indicator-size: 2rem ;
$font-size-base: 1.6rem;
$line-height-base: 1.6;
$component-active-bg: $primary;
$custom-control-indicator-checked-disabled-bg: var(--primary-lighter, $primary-lighter) !default;


$ln-focus-color : var(--ln-focus-color, transparentize(  $primary, 0.3) );

$border-radius:               .8rem ;
$border-radius-lg:            1.6rem ;
$border-radius-sm:            .8rem;

$card-cap-bg:                  $white;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1300px
);

$lecture-z-index: 100;
$zindexes: 1,2,3,4,5,6,7,8,9,10;
$font-family-sans-serif: 'PT Sans', sans-serif;
$tooltip-max-width: 25rem;
$tooltip-bg: $white;
$tooltip-color: black;

$orange: #cc5c01;
$warning-light: #ebe4cf;

$body-color: var(--body-text-color, #101010);
$table-color: $body-color;

$primary-darker:     darken($primary, 10%);