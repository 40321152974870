
.th-clean {
    border-top: none !important;
    @include rem-fallback(font-size, 14);
    @include rem-fallback(min-width, 12);
}


.ln-table-responsive {
    .td-label {
        display: none;
    }
}


/*
	Max width before table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
    @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
        .ln-table-responsive {
            display: block;
            /* Force table to not be like tables anymore */
            thead,
            tbody,
            th,
            td,
            tr {
                display: block;
            }

            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

            tr {
                margin: 0 0 1rem 0;
            }

            td {
                /* Behave  like a "row" */
                border: none;
                border-bottom: 1px solid #eee;
                position: relative;
                /*padding-left: 50%;*/
            }

            td:first-child{
                font-weight: bold;
            }

            td:last-child{
                border-bottom: solid 2px var(--primary, $primary);
            }

            .td-label {
                display: inline-block;
                @include rem-fallback(width, 100);
                @include rem-fallback(min-width, 100);
                @include rem-fallback(font-size, 12);
            }

        }
    }
