dialog{
  color: $modal-content-color;
  pointer-events: auto;
  background-color: $modal-content-bg;
  background-clip: padding-box;
  border: $modal-content-border-width solid $modal-content-border-color;
  @include border-radius($modal-content-border-radius);
  @include box-shadow($modal-content-box-shadow-xs);
  position: fixed;
  z-index: 10000;
  top: 1rem;

  &::backdrop{
    color: white;
    background: rgba(0, 0, 0, 0.4);
  }


  &.md{
      min-width: 50rem;
      max-width: 50%;
  }

  &.lg{
      max-width: 100rem;
      
  }
  
  &.xl{
      width: 80%;
      height: 90%;
      display: flex;
      flex-direction: column;

      .modal-body{
          flex-grow: 1;
          overflow-y: auto;
      }
  }

}