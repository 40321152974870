@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";

.pre-wrap {
  white-space: pre-wrap !important;
}

.post-title {
  font-weight: 700;
}


.feed-comment {
  position: relative;
  .comment-avatar {
    @include rem-fallback(margin-right, 10);
  }
  .comment-card {
    background-color: $card-cap-bg;
    border: unset;
    @include rem-fallback(padding, 10);
    width: 100%;
  }
}

.feed-form {
  background-color: $card-cap-bg;
  @include rem-fallback(border-radius, 10);
  @include rem-fallback(max-width, 820);
  @include rem-fallback(padding, 10);
  
}

@media (max-width: 550px) {
  .post-card {
    @include rem-fallback(margin-left, -21);
    @include rem-fallback(margin-right, -21);
    border-radius: unset;
    .post-text-wrapper {
      @include rem-fallback(margin-left, -40);
    }
  }
}
