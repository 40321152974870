/*Side menubar css*/
.slide-panel-collapse {
	position: relative;
	.menu-sidebar {
		@include rem-fallback(left, -275);
		@include media-breakpoint-down(md) {
			left: 0;
		}
	}
	.wrapper {
		margin: 0
	}
	.nicescroll-cursors {
		@include rem-fallback(right, -275);
	}
}
.menu-sidebar {
	@include rem-fallback(padding, 29,10);
	background-color: $white;
	@include rem-fallback(width, 275);
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 10;
	overflow: auto;
	@include box-shadow(1.414px, -1.414px, 5px, $black_2);
	@include transition(all 0.3s);
	@include media-breakpoint-down(md) {
		@include rem-fallback(left, -275);
	}
	@include media-breakpoint-down(sm) {
		@include rem-fallback(padding, 10);
	}
	.close-menubar {
		display: none;
		text-align: right;
		@include rem-fallback(margin, 0,0,15);
		cursor: pointer;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		@include media-breakpoint-down(md) {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
		}
		i {
			@include rem-fallback(width, 25);
			@include rem-fallback(height, 40);
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			text-align: center;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
	.site-logo {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		@include rem-fallback(margin, 0,0,40);
		img {
			@include rem-fallback(width, 180);
			height: 100%;
		}
	}
	.menubar-list {
		@include rem-fallback(margin, 0,0,30);
	}
	.list-item {
		border: 1px solid $border-grey;
		@include border-radius(10px);
		@include rem-fallback(margin, 0,0,11);
		position: relative;
		&:last-child {
			margin: 0;
		}
		a {
			@include rem-fallback(font-size, 18);
			@include rem-fallback(line-height, 22);
			color: $secondary;
			font-weight: 400;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: start;
			-webkit-align-items: flex-start;
			-ms-flex-align: start;
			align-items: flex-start;
			// @include rem-fallback(padding,10,40,10,15);
			position: relative;


			img {

				@include rem-fallback(width, 25);
			}
			.book-img {
				@include rem-fallback(margin,3,12,0,0);
			}
		}
		.right-arrow {
			position: absolute;
			@include rem-fallback(right, 15);
			@include rem-fallback(top, 16);
			@include rem-fallback(max-width, 12);
			@include transition(all 0.4s);

		}
		&.has-list {
			@include transition(all 0.4s);
			a {
				@include transition(all 0.4s);
			}
			
		}
		.inner-list {
			@include rem-fallback(margin, 15,0,20);
			display: none;
			li {
				@include rem-fallback(margin,0,0,15);
				&:last-child {
					margin: 0;
				}
				a {
					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
					@include rem-fallback(padding,0,35,0,15);
					@include rem-fallback(font-size, 16);
					@include rem-fallback(line-height, 20);
					color: $secondary;
					font-weight: 400;
					position: relative;
					img {
						@include rem-fallback(margin, 2,10,0,0);
						@include rem-fallback(max-width, 18);
					}
				}
			}
		}
	}
}

/*nicescroll css*/
.nicescroll-cursors {
	background-color: $grey_1 !important;
}



.header-text {
	@include media-breakpoint-down(xs) {
		text-align: right;
	}
	.dropdown-text {
		@include rem-fallback(width, 270);
		@include rem-fallback(padding, 0,5);
		@include media-breakpoint-down(lg) {
			@include rem-fallback(width, 235);
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
			padding: 0;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: reverse;
			-webkit-flex-direction: row-reverse;
			-ms-flex-direction: row-reverse;
			flex-direction: row-reverse;
		}
		.dropdown-arrow {
			position: absolute;
			@include rem-fallback(left, 1);
			margin:0;
		}
		span {
			@include rem-fallback(padding-left, 16);
		}
	}
	.custom-dropdown {
		.dropdown-list {
			@include rem-fallback(min-width, 190);
			right: 0;
		}
	}
	h4 {
		color: $white;
		font-weight: 400;
		@include rem-fallback(padding, 0,0,0,20);
		@include media-breakpoint-down(md) {
			@include rem-fallback(padding, 0,0,0,20);
		}
		@include media-breakpoint-down(xs) {
			@include rem-fallback(padding, 0,0,0,20);
		}
	}
}
.header-right {
	z-index: 4;
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
	.header-listng {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		@include media-breakpoint-down(sm) {
			-webkit-box-pack: end;
			-webkit-justify-content: flex-end;
			-ms-flex-pack: end;
			justify-content: flex-end;
		}
		@include media-breakpoint-down(xs) {
			-webkit-box-pack: justify;
			-webkit-justify-content: space-between;
			-ms-flex-pack: justify;
			justify-content: space-between;
		}
		li {
			cursor: pointer;
		}
	}
	.information-dropdown {
		@include rem-fallback(margin, 0,41,0,0);
		@include media-breakpoint-down(lg) {
			@include rem-fallback(margin, 0,15,0,0);
		}
		@include media-breakpoint-down(xs) {
			@include rem-fallback(margin, 0,10,0,0);
		}
		.custom-dropdown {
			background-color: $black_3;
			@include border-radius(23px);
			.dropdown-text {
				@include rem-fallback(font-size, 18);
				@include rem-fallback(line-height, 18);
				font-weight: 400;
				@include rem-fallback(padding, 15,15,15,20);
				@include rem-fallback(min-width, 190);
				width: 100%;
				-webkit-box-pack: justify;
				-webkit-justify-content: space-between;
				-ms-flex-pack: justify;
				justify-content: space-between;
				@include media-breakpoint-down(lg) {
					@include rem-fallback(min-width, 185);
				}
				@include media-breakpoint-down(md) {
					@include rem-fallback(min-width, 170);
				}
				@include media-breakpoint-down(sm) {
					@include rem-fallback(min-width, 200);
				}
				@include media-breakpoint-down(xs) {
					@include rem-fallback(min-width, 150);
					@include rem-fallback(padding, 10,20);
					@include rem-fallback(font-size, 16);
					@include rem-fallback(line-height, 16);
				}
				@include media-breakpoint-down(xs) {
					@include rem-fallback(min-width, 130);
				}
				@include media-breakpoint-down(xs) {
					@include rem-fallback(min-width, 100);
				}
				span {
					@include media-breakpoint-down(xs) {
						@include text-truncate();
						@include rem-fallback(min-width, 80);
						display: block;
					}
					@include media-breakpoint-down(xs) {
						@include rem-fallback(min-width, 60);
					}
					@include media-breakpoint-down(xs) {
						@include rem-fallback(min-width, 45);
					}
				}
			}
			.dropdown-list {
				@include rem-fallback(min-width,200);
			}
			li {
				&:nth-last-of-type(2) {
					@include rem-fallback(padding, 0,0,5);
				}
				&.inner-dropdown {
					border-top: 1px solid $border-grey;
					a {
						-webkit-box-align: center;
						-webkit-align-items: center;
						-ms-flex-align: center;
						align-items: center;
						-webkit-box-pack: justify;
						-webkit-justify-content: space-between;
						-ms-flex-pack: justify;
						justify-content: space-between;
						@include rem-fallback(padding, 5,20,0);
						img {

							@include rem-fallback(width, 12);
						}
					}
					span {
						@include rem-fallback(margin, 0,7,0,0);
					}
				}
			}
		}
	}
	.notification {
		position: relative;
		@include rem-fallback(margin, 0,29,0,0);

		@include media-breakpoint-down(lg) {
			@include rem-fallback(margin, 0,15,0,0);
		}
		@include media-breakpoint-down(xs) {
			@include rem-fallback(margin, 0,15,0,0);
		}
		img {
			@include rem-fallback(width, 25);

			@include media-breakpoint-down(xs) {
				@include rem-fallback(width, 20);
			}
		}

	}
	.search {
		@include rem-fallback(margin, 0,30,0,0);
		position: relative;
		@include media-breakpoint-down(lg) {
			@include rem-fallback(margin, 0,15,0,0);
		}
		img {
			@include rem-fallback(margin, 0,0,3);
			@include rem-fallback(width, 20);
		}
		.search-block {
			position: absolute;
			@include rem-fallback(right, -20);
			@include rem-fallback(top, 36);
			@include transition(0.3s);
			opacity: 0;
			visibility: hidden;
			cursor: auto;
			@include media-breakpoint-down(md) {
				@include rem-fallback(top, 40);
			}
			@include media-breakpoint-down(xs) {
				@include rem-fallback(right,-122);
			}
			.arrow-dropdown {
				&:before {
					@include media-breakpoint-down(xs) {
						right: 40%;
					}
				}
			}
			&.open {
				opacity: 1;
				visibility: visible;
			}
			.search-row {
				@include rem-fallback(width, 467);
				@include box-shadow(0, 0, 5px, $black_1);
				@include border-radius(10px);
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: justify;
				-webkit-justify-content: space-between;
				-ms-flex-pack: justify;
				justify-content: space-between;
				@include rem-fallback(padding, 20);
				background-color: $white;
				@include media-breakpoint-down(md) {
					@include rem-fallback(padding, 15);
				}
				@include media-breakpoint-down(sm) {
					@include rem-fallback(width, 450);
				}
				@include media-breakpoint-down(xs) {
					@include rem-fallback(padding, 10,15);
					@include rem-fallback(width, 290);
				}
			}
			.primary-btn {
				@include media-breakpoint-down(xs) {
					@include rem-fallback(height, 40);
				}
			}
			.search-input {
				@include rem-fallback(margin, 0,20,0,0);
				width: 100%;
				/*max-width: 152px;*/
				border: none;
				@include media-breakpoint-down(xs) {
					@include text-truncate();
				}
			}
		}
	}
	.country-dropdown-list {
		@include rem-fallback(margin, 0,25,0,0);
		@include media-breakpoint-down(lg) {
			@include rem-fallback(margin, 0,15,0,0);
		}
		@include media-breakpoint-down(xs) {
			@include rem-fallback(margin, 0,10,0,0);
		}
	}
	.user-info {
		.dropdown-text {
			padding: 0;
			.user-img {
				margin: 0;
				display: -webkit-inline-box;
				display: -webkit-inline-flex;
				display: -ms-inline-flexbox;
				display: inline-flex;
				@include media-breakpoint-down(lg) {
					@include rem-fallback(width, 37);
				}
				@include media-breakpoint-down(xs) {
					@include rem-fallback(width, 40);
				}
				@include media-breakpoint-down(xs) {
					@include rem-fallback(width, 35);
				}
			}
		}
		.dropdown-list {
			@include rem-fallback(width, 250);
			@include rem-fallback(right, 20);
			@include rem-fallback(top, 70);
			@include rem-fallback(padding, 10,15);
			@include media-breakpoint-down(md) {
				@include rem-fallback(top, 65);
			}
			@include media-breakpoint-down(xs) {
				@include rem-fallback(top, 55);
				right: 0;
			}
			li {
				&.user-name {
					border-bottom: 1px solid $border-grey;
					@include rem-fallback(padding, 0,0,10);
					a {
						@include rem-fallback(line-height, 26);
						.user {
							display: block;
							line-height: 100%;
							margin: 0;
							width: 100%;
						}
					}
				}
				&.tech-info, &.system-info {
					border-bottom: 1px solid $border-grey;
					@include rem-fallback(padding, 7,0,10);
				}
				&.logout {
					@include rem-fallback(padding, 5,0,0);
					a {
						img {
							@include rem-fallback(margin, 0,12,0,0);
							@include rem-fallback(width, 20);
						}
					}
				}
				a {
					-webkit-box-align: center;
					-webkit-align-items: center;
					-ms-flex-align: center;
					align-items: center;
					padding: 0;
					i {
						display: -webkit-inline-box;
						display: -webkit-inline-flex;
						display: -ms-inline-flexbox;
						display: inline-flex;
						-webkit-box-pack: center;
						-webkit-justify-content: center;
						-ms-flex-pack: center;
						justify-content: center;
						@include rem-fallback(width, 20);
						@include rem-fallback(margin,0,12,0,0);
						img {
							max-width: 100%;
						}
					}
				}
			}
		}
	}
}
.red-circle {
	position: absolute;
	@include rem-fallback(right, 7);
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	@include rem-fallback(width, 20);
	@include rem-fallback(height, 20);
	color: $white;
	@include border-radius(10px);
	background-color: $red;
}

