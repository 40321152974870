@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";
@import "assets/scss/core/mixins/_breakpoints.scss";
@import "assets/scss/lnvariables.scss";

.widget-card {
  max-width: 100%;
  min-width: 100%;
  min-height: 36rem;
  max-height: 36rem;
  overflow: hidden;
  padding: 0 !important;
  &.half {
    min-height: 15.9rem;
  }

  :global(.card-body) {
    overflow-y: auto;
    overflow-x: hidden;
  }

  @include media-breakpoint-down(sm) {
    min-height: auto;
    max-height: none;
  }
}

.access-type-icon {
  @include rem-fallback(height, 44);
  @include rem-fallback(width, 44);
  @include rem-fallback(margin-right, 20);
  object-fit: fill;
}

.card-header {
  background-color: unset;
}

.my-progress {
  .green span {
    background: var(--primary, $primary);
  }
  .pink span {
    background: $pink;
  }
  .yellow span {
    background: $yellow;
  }
  .myprogress-chart {
    width: 70%;
    margin: 0 auto;
    @include rem-fallback(margin-bottom, 6);
  }

  .doughnut-listing {
    border: unset;
    // width: 50%;
  }
}

.time-table-card {
  .event-item {
    @include rem-fallback(padding-bottom, 20);
  }
  .event-badge {
    @include rem-fallback(font-size, 16);
    @include rem-fallback(padding, 6, 14);
    background-color: $success !important;
    color: $white !important;
  }
}
.message-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @include rem-fallback(min-height, 30);
  &.event {
    -webkit-line-clamp: 1;
    @include rem-fallback(min-height, 15);
  }
}

.message-item::before,
.message-modal-body::before {
  background-color: #95cd81;
  content: "";
  position: absolute;
  top: 1rem;
}
.message-item::before {
  height: 80%;
  left: 0;
  @include rem-fallback(width, 5);
}

.message-modal-body {
  @include rem-fallback(padding-left, 30);
}

.message-modal-body::before {
  @include rem-fallback(bottom, 10);
  @include rem-fallback(left, 12);
  @include rem-fallback(width, 6);
  z-index: 1;
}

.message-item.warning::before,
.message-modal-body.warning::before {
  background-color: $warning;
}

.message-item.danger::before,
.message-modal-body.danger::before {
  background-color: $danger;
}

.message-item.info::before,
.message-modal-body.info::before {
  background-color: $info;
}

.message-item.success::before,
.message-modal-body.success::before {
  background-color: $success;
}

.notification-type-system {
  background-color: var(--primary, $primary);
}
.notification-type-school-unit {
  background-color: var(--info, $info);
}
.notification-type-class-unit {
  background-color: var(--orange, $orange);
}

.modal-header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.dashboard_container {
  display: grid;
  max-width: 100%;
  grid-template-columns: 47% 47%;
  column-gap: 3rem;
  max-width: $max-container-width;
  margin: auto;

  @include media-breakpoint-down(sm) {
    grid-template-columns: 100%;
    max-width: 55rem;
  }
}

.classDataCard{
  max-width: 5§0rem;
  margin: 0 1.5rem 1.5rem 0;
  display: inline-block;
}

.schoolClass{
  background:   right / contain no-repeat url("/");
}