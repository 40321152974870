@import "assets/scss/core/_functions";
@import "assets/scss/core/_variables";
@import "assets/scss/base/mixins.scss";

.btn-small {
  @include rem-fallback(height, 36);
}

.dl-row {
  @include rem-fallback(margin-right, 30);
}

.view-link {
  cursor: pointer;
  position: absolute;
  right: 0;
}

.currentClass {
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    margin-left: -2rem;
    top: 0.5rem;
    @include rem-fallback(width, 12);
    @include rem-fallback(height, 12);
    border-radius: 50%;
    background-color: var(--primary, $primary);
    margin-right: 10px;
  }
}
