@import "../../assets/scss/core/functions";
@import "../../assets/scss/core/variables";
@import "../../assets/scss/base/colors.scss";
@import "../../assets/scss/base/mixins.scss";
@import "../../assets/scss/core/mixins";




.dropDownMenu {
    @include rem-fallback(min-width, 170);
}

.caret{
    position: absolute;
    right: -1.7rem;
}

.toggle{
    min-width: 4.6rem;
    min-height: 4.6rem;
    padding-top: 0.8rem;
    &:hover{
        padding-top: 0.8rem;
    }
}

.flag {

    img {
        @include rem-fallback(width, 25);
        @include rem-fallback(height, 19);
        position: relative;
        @include rem-fallback(top, 3);
        @include rem-fallback(left, 3);
    }
}

.flagItem {
    font-size: initial;
    @include rem-fallback(margin-bottom, 5);

    img {
        @include rem-fallback(width, 25);
    }
}


