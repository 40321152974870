@import "lnvariables.scss";

.pointer {
    cursor: pointer;
}

.box-shadow-black{
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.23);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.23);
}

.min-width-6rem{
    min-width: 6rem;
}

.ul-fix{
    ul{
        overflow: hidden;
    }
}

.columns-2{
    columns: 2 ;
    column-width: calc( 50% - 4rem );
    column-rule: 4px dotted var(--primary, $primary);
    column-gap: 8rem;
}



@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      @each $prop, $abbrev in (width: w, height: h) {
        @each $size, $length in $sizes {
          .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
        }
      }
    }
  }