@mixin table-row-variant-legacy($state, $background) {
    // legacy from old online Boostrap 3 where   <tr class="success" >  could be used

    .#{$state} {
        &,
        > th,
        > td {
            background-color: $background;
        }
    }
}

$widths: () !default;

$widths: map-merge(
    (
        2: 2px,
        3: 3px,
        4: 4px,
        5: 5px,
    ),
    $widths
);

@each $s, $w in $widths {
    .border-w#{$s} {
        border-width: $w !important;
    }
}

$caret-width: 0.8rem;