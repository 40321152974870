.page-content-block {
  @include rem-fallback(padding, 0, 01);
  position: relative;
  z-index: 3;
  @include rem-fallback(margin-top, 0);

  @include media-breakpoint-down(sm) {
    @include rem-fallback(padding, 0, 15);
  }
}
.card-listing {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  @include rem-fallback(margin, 0, -10);
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  > li {
    @include rem-fallback(padding, 0, 10);
    @include rem-fallback(margin-bottom, 20);
    color: $secondary;
    @include media-breakpoint-down(md) {
      width: 100%;
      height: auto !important;

      .card-box-title {
        height: auto !important;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .time-table-course-listing {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    > li {
      height: 50%;
      -webkit-filter: drop-shadow(0px -2px 8px rgba($black, 0.06));
      filter: drop-shadow(0px -2px 8px rgba($black, 0.06));
      .card-box {
        filter: none;
      }
      &:first-child {
        padding: 0 0 10px;
      }
      &:last-child {
        padding: 10px 0 0;
      }
    }
  }
}
.card-box {
  @include border-radius(10px);
  height: 100%;
  background: $white;
  -webkit-filter: drop-shadow(0px -2px 8px rgba($black, 0.06));
  filter: drop-shadow(0px -2px 8px rgba($black, 0.06));
  &.member-card-box {
    min-height: 100vh;
    .card-box-content {
      @include rem-fallback(padding, 28);
    }
  }
  &.time-table-block {
    .card-box-inner-listing {
      li {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        .badge {
          @include rem-fallback(margin-right, 13);
          font-weight: 700;
          @include rem-fallback(font-size, 18);
          @include rem-fallback(line-height, 22);
          @include rem-fallback(padding, 6, 15);
          @include media-breakpoint-down(lg) {
            @include rem-fallback(margin-right, 10);
            @include rem-fallback(padding, 5, 10);
            @include rem-fallback(font-size, 16);
            @include rem-fallback(line-height, 20);
          }

          em {
            display: block;
            @include rem-fallback(font-size, 14);
            @include rem-fallback(line-height, 18);
            text-transform: uppercase;
            font-weight: 400;
          }
        }
      }
    }
  }
  .card-box-title {
    @include rem-fallback(padding, 10, 19, 13, 17);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 60px;
    border-bottom: 2px solid $border-grey-dark;
    h3 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      i {
        @include rem-fallback(margin-right, 10);
        @include rem-fallback(width, 30);

        img {
          width: auto;
          max-width: 100%;
        }

        @include media-breakpoint-down(lg) {
          @include rem-fallback(height, 25);
          @include rem-fallback(width, 25);
        }
      }
    }
    .card-box-link {
      @include rem-fallback(padding, 20, 18);
      @include rem-fallback(margin, -20, -18, -20, 0);
      @include rem-fallback(font-size, 16);
      @include rem-fallback(line-height, 20);
      color: var(--primary, $primary);
      @include media-breakpoint-down(lg) {
        @include rem-fallback(padding, 17, 18);
        @include rem-fallback(margin, -17, -19, -17, 0);
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .card-box-content {
    @include rem-fallback(padding, 10, 20, 10);

    &.about-block {
      p {
        @include rem-fallback(padding-right, 10);
        @include rem-fallback(line-height, 19);
        @include media-breakpoint-down(lg) {
          padding-right: 0;
        }
      }
    }
    p {
      @include rem-fallback(margin-bottom, 22);
      text-align: left;
      line-height: 1.4;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .card-box-inner-listing {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      @include rem-fallback(margin, 0, -15);
      @include media-breakpoint-down(md) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 0;
      }
      &.progress-inner-listing {
        @include rem-fallback(padding, 11, 31, 0, 22);
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        @include rem-fallback(margin, 0, -15, 10);
        @include media-breakpoint-down(lg) {
          padding: 0;
        }
        @include media-breakpoint-down(xs) {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          @include rem-fallback(margin, 0, 0, 10);
        }

        > li {
          width: 50%;
          @include media-breakpoint-down(md) {
            @include rem-fallback(margin-bottom, 0);
            @include rem-fallback(padding, 0, 15);
          }
          @include media-breakpoint-down(xs) {
            width: 100%;
            padding: 0;
            @include rem-fallback(margin-bottom, 20);
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      > li {
        @include rem-fallback(padding, 0, 15);
        width: 50%;
        text-align: center;
        @include media-breakpoint-down(md) {
          width: 100%;
          @include rem-fallback(margin-bottom, 20);
          padding: 0;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .circle-border-block {
          @include rem-fallback(max-width, 165);
          @include rem-fallback(margin-bottom, 19);
          width: 100%;
          @include media-breakpoint-down(lg) {
            @include rem-fallback(max-width, 120);
          }
          @include media-breakpoint-down(sm) {
            @include rem-fallback(max-width, 100);
          }
        }
        .large-text {
          @include rem-fallback(font-size, 18);
          @include rem-fallback(line-height, 22);
          text-align: center;
        }
        i {
          @include rem-fallback(margin-bottom, 15);
        }
      }
    }
    .test-listing {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      @include rem-fallback(margin, 0, -10);
      @include rem-fallback(font-size, 14);
      @include rem-fallback(line-height, 18);
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: left;
      @include media-breakpoint-down(xs) {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
      li {
        @include rem-fallback(padding, 0, 13, 0, 24);
        @include rem-fallback(margin-bottom, 6);
        color: $grey_4;
        &:before {
          @include rem-fallback(left, 3);
        }
      }
    }
    .course-duration-listing {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      @include rem-fallback(margin, 0, -10);
      @include rem-fallback(padding-top, 16);
      @include media-breakpoint-down(md) {
        margin: 0;
        padding-top: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
      }
      li {
        @include rem-fallback(padding, 0, 10);
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 50%;
        position: relative;
        &::after {
          position: absolute;
          @include rem-fallback(right, 12);
          content: "";
          background: $grey_3;
          @include rem-fallback(width, 1);
          top: 0;
          bottom: 0;
        }
        @include media-breakpoint-down(xl) {
          &::after {
            right: 0;
          }
        }
        @include media-breakpoint-down(md) {
          width: 100%;
          @include rem-fallback(padding, 0, 0, 10);
          @include rem-fallback(margin, 0, 0, 10);
          &:last-child {
            padding: 0;
            margin: 0;
          }
          &:after {
            @include rem-fallback(height, 1);
            width: 100%;
            bottom: 0;
            left: 0;
            right: 0;
            top: auto;
          }
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        i {
          position: relative;
          @include rem-fallback(top, 3);
          @include rem-fallback(height, 40);
          @include rem-fallback(width, 40);
          @include rem-fallback(margin-right, 12);

          img {
            width: auto;
          }
          @include media-breakpoint-down(sm) {
            @include rem-fallback(height, 30);
            @include rem-fallback(width, 30);
          }
        }
        .duration-info-block {
          width: calc(100% - 52px);
          p {
            @include rem-fallback(margin-bottom, 6);
            &:last-child {
              margin-bottom: 0;
            }
            span {
              font-weight: 700;
            }
          }
        }
      }
    }
    .badge-text {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      .round-badge {
        @include rem-fallback(margin-right, 5);
        position: relative;
        @include rem-fallback(top, 2);
        @include media-breakpoint-down(lg) {
          top: 0;
        }
      }
    }
  }
  .event-login-heading {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @include media-breakpoint-down(xs) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    @include rem-fallback(margin-bottom, 15);
    p {
      @include rem-fallback(font-size, 14);
      @include rem-fallback(line-height, 18);
      position: relative;
      @include rem-fallback(top, -3);
      margin: 0;
      @include media-breakpoint-down(xl) {
        top: 0;
      }
      @include media-breakpoint-down(lg) {
        @include rem-fallback(margin-bottom, 5);
      }
    }
    
  }
}

// message-strip

.message-strip {
  position: relative;
  cursor: pointer;
  @include rem-fallback(padding-bottom, 14);
  @include rem-fallback(margin-bottom, 12);
  display: block;
  color: $secondary;
  &:hover,
  &:visited,
  &:active {
    color: $secondary;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &::after {
    position: absolute;
    content: "";
    @include rem-fallback(left, 0);
    @include rem-fallback(height, 1);
    bottom: 0;
    background: $grey_2;
    right: 0;
  }
  &.border-block {
    &::before {
      @include rem-fallback(bottom, 17);
      @include rem-fallback(top, 2);
    }
  }
  .message-heading {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @include rem-fallback(font-size, 13);
    @include rem-fallback(line-height, 17);
    @include rem-fallback(margin, 0, 0, 3, -1);

    i {
      @include rem-fallback(height, 17);
      @include rem-fallback(width, 17);
      @include rem-fallback(margin-right, 5);
    }
    .badge {
      @include rem-fallback(margin-left, 9);
    }
  }
  p {
    &.bold-text {
      font-weight: 700;
    }
  }
}

// event strip
.event-strip {
  @include rem-fallback(padding-bottom, 13);
  @include rem-fallback(margin-bottom, 12);
  border-bottom: 1px solid $grey_2;
  width: 100%;
  .event-strip-wrapper {
    max-width: 70%;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
  }
  h5 {
    font-weight: 700;
    color: $secondary;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

body:not(.slide-panel-collapse) {
  .card-listing {
    > li {
      @include media-breakpoint-down(lg) {
        width: 100%;
        height: auto !important;
      }
    }
  }
}
.big-card-box {
  .card-title{
    @include media-breakpoint-down(xs) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      justify-content: center;
      h3{
        @include rem-fallback(margin-bottom, 10);
      }
    }
  }
  .event-login-heading {
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .custom-input {
      @include media-breakpoint-down(sm) {
        margin-left: 0px;
      }
    }
  }
}

@media print {
  .card-box {
    border: unset;
    filter: unset;
  }
}
