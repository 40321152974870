.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

img.pull-right {
  margin: 0 0 3px 5px;
  @include media-breakpoint-down(xs) {
    max-width: 50%;
  }
}

img.pull-left {
  margin: 0 5px 3px 0;
  @include media-breakpoint-down(xs) {
    max-width: 50%;
  }
}

.box {
  /* stolen from panel (panels.less) */
  min-height: 20px;
  padding: 5px;
  margin-bottom: $line-height-sm;
  background-color: $white;
  border: 1px solid $card-border-color;
  border-top-width: 1rem;
  @include border-radius($card-border-radius);
  @include box-shadow(0 1px 1px rgba(0, 0, 0, 0.05));

  &.Facts {
    border-color: var(--primary-light, $primary-light);
  }

  &.Info {
    border-color: var(--warning, $warning);
  }

  &.leftbox,
  &.rightbox {
    margin: 5px 8px 5px 0;
  }

  @include media-breakpoint-up(sm) {
    &.leftbox {
      float: left;
      margin: 5px 8px 5px 0;
    }
  }
  
  @include media-breakpoint-up(sm){
    &.rightbox {
      float: right;
      margin: 5px 0 5px 8px;
    }
  }

  &.w150 {
    width: #{ 'min( 100%, 15rem)'};
  }


  &.w200 {
    width: #{ 'min( 100%, 20rem)'};
  }

  &.w250 {
    width: #{ 'min( 100%, 25rem)'};
  }


  &.w300 {
    width: #{ 'min( 100%, 30rem)'};
  }


  &.w350 {
    width: #{ 'min( 100%, 35rem)'};
  }


  &.w400 {
    width: #{ 'min( 100%, 40rem)'};
  }

  &.w450 {
    width: #{ 'min( 100%, 45rem)'};
  }


  &.w500 {
    width: #{ 'min( 100%, 50rem)'};
  }

  &.w550 {
    width: #{ 'min( 100%, 55rem)'};
  }

}
