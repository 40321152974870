/* Reset
-----------*/
* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.wrapper {
  min-height: 100%;
  @include transition(all 0.3s);
  position: relative;
  @include rem-fallback(margin, 0, 0, 0, 275);

  @include media-breakpoint-down(md) {
    margin: 0;
  }
}

html {
  font-size: 62.5%;
  height: 100%;
  // visibility: hidden;

  &.menu-open {
    overflow: hidden;
  }

  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}

body {
  padding: 0;
  margin: 0;
  @include rem-fallback(font-size, 16);
  @include rem-fallback(line-height, $normal-line-height);
  font-weight: 400;
  overflow-x: hidden;
  background: $bg-grey;
  height: 100%;
  -webkit-font-smoothing: antialiased;

  &.menu-open {
    overflow: hidden;
  }
}

*::-webkit-scrollbar {
  @include rem-fallback(width, 11);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  @include rem-fallback(border-radius, 6);
  border: 0.3rem solid var(--scrollbarBG);
}

a {
  color: var(--link-color, $link-color);
  @include transition(0.2s all);

  i {
    @include rem-fallback(margin, 0, 5, 0, 0);
    position: relative;

    .hover-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  &:hover,
  // &:visited,
  &:active {
    text-decoration: $link-hover-decoration;
    outline: none;
    box-shadow: none;
    color: var(--link-hover-color, $primary);

    .hover-img {
      opacity: 1;
    }

    .normal-img {
      opacity: 0;
    }
  }
}

img {
  object-fit: cover;
}

.fill {
  object-fit: fill;
}

i {
  display: inline-block;

  img {
    height: 100%;
    width: 100%;
  }
}

button {
  cursor: pointer;
  @include transition(all 0.3s);

  &:hover,
  &:focus {
    outline: none;
  }
}

::-moz-selection {
  background: var(--primary, $primary);
  color: $white;
}

::selection {
  background: var(--primary, $primary);
  color: $white;
}

::-webkit-resizer {
  background-image: url(/imgs/webkit-resizer.svg);
}

.list-item-active {
  background-color: $bg-grey;
}

// .custom-tab-heading-list

.custom-tab-heading-list {
  display: flex;
  // border-bottom: 1px solid $grey_8;
  flex-wrap: wrap;
  
  //grid-template-columns: repeat( auto-fit, minmax(10rem, 1fr));

  // &:last-child {
  //   margin: 0;
  // }

  button {
    height: fit-content;
    padding: 0;
    white-space: initial;
    align-items: initial;
    justify-content: left;
    border: none;
    background: transparent;
    color: var(--link-color, $link-color);
    border-radius: 0.2em;
    font-size: inherit;
    @include rem-fallback(min-width, 20);

    &:disabled {
      opacity: 70%;
      &:after{
        background: none;
      }
      &:hover {
        color: initial;
        text-decoration: none;
       
      }
    }
  }

  a,
  button {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--body-text-color, $gray-900);
    @include rem-fallback(margin-right, 11);
    @include rem-fallback(margin-bottom, 11);
    @include rem-fallback(padding, 5, 10, 10);
    @include rem-fallback(font-size, 16);
    @include rem-fallback(line-height, 10);

  
    @include media-breakpoint-down(xs) {
      @include rem-fallback(padding, 4, 4, 10);
      @include rem-fallback(font-size, 14);
      @include rem-fallback(line-height, 10);
    }

    &.active {
      color: var(--primary, $primary);

      &:after {
        left: -0.1rem;
        width: calc( 100% + 0.2rem);
      }
    }

    &:after {
      position: absolute;
      content: "";
      right: -0.2rem;
      bottom: -0.1rem;
      height: 0.3rem;
      border-bottom-left-radius: 0.15rem;
      border-bottom-right-radius: 0.15rem;
      transition: all 0.3s;
      -o-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      background: var(--primary, $primary);
      background: linear-gradient(180deg, var(--primary, $primary) 60%, rgba(0,0,0,0) 100%);
      width: 0;
    }

    &:hover {
      color: var(--primary, $primary);
      text-decoration: none;
      &:after {
        left: 0;
        width: 100%;
      }
    }
  }
}

.root {
  position: relative;
  height: 100%;
}

.card-box-content {
  p {
    margin-bottom: 1rem !important;
    text-align: initial;
  }
}

.scrollContent {
  position: absolute;
  bottom: 0;
  @include rem-fallback(top, 85);
  left: 0;
  right: 0;
  overflow-y: auto;
  padding: 1rem 2.8rem 0 2.8rem;
  

  @include media-breakpoint-down(md) {
    position: relative;
    top: 0;
    padding: 0 1.4rem 0 1.4rem;
    &::before {
      content: "";
      position: absolute;
      height: 7rem;
      width: 100%;
      background-color: var(--primary, $primary);
      left: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 0;
    .card-box {
      border-top-right-radius: unset;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;

      .card-box-content {
        padding: 1rem 0 1rem;
      }
    }
  }
}

.scrollBg {
  position: absolute;
  height: 7rem;
  width: 100%;
  background-color: var(--primary, $primary);
  left: 0;
  top: 8rem;
}

@media print {
  body {
    background-color: unset;
  }
  .scrollContent::before {
    background-color: unset;
  }
}

.logoBox {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 7.5rem;
  background: url("/imgs/logo.png") no-repeat center/18rem 3.8rem;
}
