input {
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;
  @include placeholder {
    color: $gray-primary;
  }
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
  &::-ms-clear {
    display: none;
  }
}
.custom-input {
  @include rem-fallback(height, 42);
  @include border-radius(23px);
  @include rem-fallback(padding, 3, 15);
  &:focus {
    border-color: var(--primary, $primary);
  }
}

.errorMessage {
  @include rem-fallback(font-size, 14);
  color: $orange;
  font-weight: 700;
  margin-left: 10px;
  margin-top: 5px;
}

form {
  .form-bottom {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: space-between;
    @include rem-fallback(margin, 0, 0, 10);
    label,
    a {
      color: $secondary;
      @include rem-fallback(font-size, 16);
      @include rem-fallback(line-height, 24);
      margin-bottom: 0;
      @include media-breakpoint-down(xs) {
        @include rem-fallback(font-size, 14);
        @include rem-fallback(line-height, 22);
      }
    }
    .forgot-password {
      &:hover {
        color: var(--primary, $primary);
      }
    }
  }
  .submit-btn {
    width: 100%;
  }
}

// // badge
.badge {
  @include rem-fallback(padding, 0, 9);
  @include border-radius(9px);
  color: $white;
  @include rem-fallback(font-size, 14);
  @include rem-fallback(line-height, 18);
  font-weight: 400;
  background: var(--primary, $primary);
  &.violet-badge {
    background: var(--violet, $violet);
  }
  &.yellow-badge {
    background: var(--yellow, $yellow);;
  }
}

// round-badge
.round-badge {
  @include rem-fallback(padding, 3, 9);
  @include border-radius(50%);
  @include rem-fallback(font-size, 13);
  @include rem-fallback(line-height, 17);
  color: var(--white, $white);
  background: var(--primary, $primary);
}



// border-list
.border-list {
  position: relative;
  @include rem-fallback(padding-left, 20);
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    @include translateY(-50%);
    @include rem-fallback(width, 15);
    @include rem-fallback(height, 4);
    @include border-radius(2px);
    background: var(--primary, $primary);
  }
  &.pink-border-list {
    &::before {
      background: var(--red, $red);
    }
  }
  &.yellow-border-list {
    &::before {
      background: var(--yellow, $yellow);
    }
  }
  &.grey-border-list {
    &::before {
      background: $grey_1;
    }
  }
}



