.react-draggable {
    .modal-header {
        cursor: move;
    }

    transition: none !important;
}

//
// LäraNära print styles

@media print {
    body.modal-open {
        background-color: $white;

        & > * {
            display: none;
        }

        div.modal-backdrop {
            display: none;
        }

        div.modal {
            display: block;
            overflow-y: auto;
            position: initial;

            .modal-dialog {
                margin: 0;
                max-width: 80ch;

                .modal-content {
                    border: none;
                    

                    .modal-header {
                        border: none;
                        background-color: transparent;

                        .close{
                            display: none;
                        }
                    }

                    .modal-body {
                        border: none;
                    }

                    .modal-footer {
                        display: none;
                    }
                }
            }
        }
    }
}
