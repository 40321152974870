
.test-listing {
    border-bottom: 1px solid #e4e4e4;
    a {
        color: unset;
    }
}

.test-list-item {
    margin-left: 3.8rem;
    .icon-text {
        margin-left: -3.8rem;
    }
    width: 100%;

}

.icon-item{
    width: 13rem;
    white-space: initial !important;
}