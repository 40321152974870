.donut-complex text {
	font-family: var(--font-family-sans-serif);
	font-size: 1.2rem;
	font-weight: normal;
	text-shadow: 0 0 0.5rem #fff;
	opacity: 0;
	transition: opacity 0.35s ease-in-out 0.6s;
	&.donut-center-text{
		font-size: 1.8rem;
	}
}

.donut-complex circle {
	fill: none;
	transition: stroke-dashoffset 0.5s ease-in-out;
}

.donut-complex:not(.donut-complex--loaded) circle {
	stroke-dashoffset: 0;
}

.donut-complex--loaded text {
	opacity: 1;
}

@each $color, $value in $theme-colors {
    .donut-color-#{$color}{ stroke: var(--donut-#{$color}); }
  }

.donut-approved-stroke{ stroke: $status-approved-byteacher-color; }
.donut-passed-stroke{ stroke: $status-passed-color; }
.donut-failed-stroke{ stroke: $status-failed-color; }
.donut-notset-stroke{ stroke: $status-notset-color; }
  

  