@import "lnmixins.scss";
@import "./core/mixins/box-shadow";
@import "./lnvariables.scss";
@import "./base/colors.scss";

.dropdown-menu {
    font-size: initial;
}

.card-box {
    height: auto;
    max-width: $max-container-width;
    margin: auto;
    &.big-card-box {
        padding: 0 0.9rem;
    }

    .card-box-title {
        border-bottom: none;
    }
}

.progress {
    height: 0.6rem;
    border-radius: 0.3rem;

    &.primary {
        .progress-bar {
            background-color: var(--primary, $primary);
        }
    }
}

.icon {
    @include rem-fallback(height, 24);
    @include rem-fallback(width, 24);
    object-fit: fill;
    &.small {
        @include rem-fallback(height, 18);
        @include rem-fallback(width, 18);
    }
    &.natural {
        height: auto;
        width: auto;
        object-fit: cover;
    }

    &.more {
        object-fit: cover;
    }
}

.alert-grey {
    color: inherit;
    background-color: $gray-300;
    border-color: $gray-300;
}

h5.modal-title {
    font-weight: bold;
}


.alert-light {
    border-color: $gray-300;
}

a,
canvas {
    &:focus {
        outline: none;
        box-shadow: 0 0 1pt 1pt var(--ln-focus-color, $ln-focus-color);
        border-radius: 0.2em;
    }
}

.preserve-white {
    white-space: pre-line;
}

.white-space-nowrap {
    white-space: nowrap;
}

.word-break-all {
    word-break: break-all;
}

.word-break-word {
    word-break: break-word;
}

.dark-caret {
    @include caret();
    &::after {
        border-top-color: var(--secondary, $secondary);
    }
}

.white-caret {
    @include caret();
    &::after {
        border-top-color: var(--white, $white);
    }
}

.white-caret-point-left {
    @include caret(left);
    &::after {
        border-right-color: var(--white, $white);
    }
}

.white-caret-point-right {
    @include caret(right);
    &::after {
        border-left-color: var(--white, $white);
    }
}

.dark-caret {
    @include caret();
    &::after {
        border-top-color: var(--secondary, $secondary);
    }
}

.dark-caret-point-left {
    @include caret(left);
    &::after {
        border-right-color: var(--secondary, $secondary);
    }
}

.dark-caret-point-right {
    @include caret(right);
    &::after {
        border-left-color: var(--secondary, $secondary);
    }
}

.dark-caret-small {
    &::after {
        display: inline-block;
        width: 0;
        height: 0;
        position: relative;
        top: -0.2rem;
        margin-left: 0.3rem;
        content: "";
        border-top: 0.6rem solid var(--secondary, $secondary);
        border-right: 0.6rem solid transparent;
        border-bottom: 0;
        border-left: 0.6rem solid transparent;
    }
}

.primary-dot {
    position: relative;
    &::before {
        display: block;
        width: 1rem;
        height: 1rem;
        position: absolute;
        left: -1.5rem;
        top: calc( 50% - 0.6rem);
        content: "";
        background-color: var(--primary, $primary);
        border-radius: 50%;
    }
}


div:focus {
    outline: none;
}

.input-group-text {
    background-color: var(--primary, $primary);
}

.minwidth400 {
    @include rem-fallback(min-width, 400);
}

.mce-content-body {
    @include border-radius($input-border-radius);
    padding: $input-padding-y $input-padding-x;
    border: 1px solid $input-border-color;
    &.mce-edit-focus {
        border-color: var(--ln-focus-color, $ln-focus-color);
    }
    @include clearfix();
}

.outerMceMenuWrapper {
    position: absolute;
    top: -0.5rem;
    width: 100%;
    overflow: visible;
}
.innerMceMenuWrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.tox-tinymce-inline {
    box-shadow: var(--ln-focus-color, $ln-focus-color) 0px 0px 5px !important;
}

.link-nocolor {
    color: var(--secondary, $secondary);
}

.off-screen {
    position: absolute;
    overflow: hidden;
    left: -10000px !important;
    top: -1000px !important;
    width: 5px;
    height: 5px;
}

.sticky-bottom {
    @supports (position: sticky) {
        position: sticky;
        bottom: 0;
        z-index: $zindex-sticky;
    }
}

//
// Fallback for content written with Bootstrap 3 (BS 4 has table-small)
//

.table-condensed {
    th,
    td {
        padding: $table-cell-padding-sm;
    }
}

@each $color, $value in $theme-colors {
    @include table-row-variant-legacy($color, theme-color-level($color, $table-bg-level));
}

.dropdown-item {
    cursor: pointer;
}

.modal-content {
    .modal-header-default {
        background-color: $card-cap-bg;
    }
}

.min-width-0 {
    min-width: 0;
}

.max-col-width {
    max-width: 80ch;
}

.selected {
    background-color: var(--primary, $primary) !important;
    color: $white !important;
}

@each $value in $overflows {
    .overflow-x-#{$value} {
        overflow-x: $value !important;
    }
}

@each $value in $overflows {
    .overflow-y-#{$value} {
        overflow-y: $value !important;
    }
}

.minw-5rem {
    min-width: 5rem;
}

.circle-sm {
    width: 2.5rem !important;
    height: 2.5rem !important;
    border-radius: 1.25rem;
}

@each $z in $zindexes {
    .zindex-#{$z} {
        z-index: $z;
    }
}

.first-line-bold {
    &::first-line {
        font-weight: bold;
    }
}

.bg-primary-lightest {
    background-color: var(--primary-lightest, $primary-lightest) !important;
}

iframe {
    max-width: 100%;
}

.tooltip-inner {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.23);
}

.dropdown-menu {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.23);
    border-radius: 1rem;
}

.under-construction {
    font-size: 7rem;
    position: absolute;
    top: 10rem;
    left: 2rem;
    font-weight: bold;
    color: #00000066;
    z-index: 15;
}

// fix for Typeahead multi line
.rbt-input-multi {
    height: auto !important;
}

// make colors for a list etc. Used in eval summary > compare

$legend-colors: (
    0: #5faa45,
    1: #aa455e,
    2: #aa6a45,
    3: #4579aa,
    4: #31b306,
    5: #b30631,
    6: #b34906,
    7: #065db3,
    8: #eec45b,
    9: #e45bee,
    10: #5b71ee,
    11: $gray-900,
    12: $gray-800,
    14: $gray-700,
    15: $black,
);

@each $num, $color in $legend-colors {
    .legend-#{$num} {
        background-color: #{$color} !important ;
    }
}

.maximizeScrollcontent {
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: $white;
    padding: 0;

    .card-box {
        max-width: 100%;
    }
}

.noHover {
    &:hover {
        background-color: initial !important;
        color: initial !important;
    }
}

.mw-75 {
    max-width: 75% !important;
}
.mh-75 {
    max-height: 75% !important;
}

input[type="url"] {
    &:valid {
        border-color: var(--primary, $primary);
    }
    &:invalid {
        border-color: var(--danger, $danger);
    }
}

.nav-tabs .nav-link{
    background-color: transparent;
}

.position-initial{
    position: initial !important;
}

.img-max-100
{
    img{
        max-width: 100% !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

li{
    margin-bottom: 1rem;
}