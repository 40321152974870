/*color variables */

$primary: #5faa45;
$success: #5faa45;
$info: #328bad;
$warning: #eec55d;
$danger: #e57373;


$primary-light:  lighten($primary, 10%);
$primary-lighter:  lighten($primary, 20%);
$primary-lightest:  lighten($primary, 35%);


$black_1: rgba(0, 0, 0, 0.23);
$black_2: rgba(0, 0, 0, 0.06);
$black_3: rgba(0, 0, 0, 0.1);
$bg-grey: #f4f4f4;
$white: #fff;


// var(--primary, $primary): #5faa45;
$secondary: #56585b;
$control-border: #e4e4e4;
$gray-primary: #9e9e9e;
$link-color: #5190d0;
$border-grey: #e2e2e2;
$violet: #7986cc;
$pink: #ffb5b7;
$border-grey-dark: #f2f2f2;
$yellow: #eec45b;
$grey_1: #b5b5b5;
$grey_2: #f0f0f0;
$grey_3: #e5e5e5;
$grey_4: #888888;
$grey_5: #b1b0b0;
$grey_6: #f6f6f6;
$grey_7: #dddddd;
$grey_8: #e1e1e1;
$blue: #a4d5ed;
$red: #e57171;
$light-green: #aed8a0;
$grey_9: #56585b;
$grey-status: #e8e8e8;
$blue_1: #4fc3f7;
$yellow_1: #eec55d;
$dark_blue_1: #488fc9;
$lavender: #ce93d8;
$red_1: #e57373;
$grey-bg: #f9f9f9;
/* $input-color:#bfbfbf; */



$test-state-colors: (
    "notset": #b1b0b0,
    "approved-byteacher": #5faa45,
    "failed": #e57171,
    "passed": #eec45b
);


$status-approved-color: #a4d5ed;
$status-approved-byteacher-color: #5faa45;
$status-passed-color: #eec45b;
$status-failed-color: #e57171;
$status-notset-color: #b1b0b0;
$status-followup-color: hsl(198, 90%, 64%);

$grade-approved-color: hsl(198, 90%, 64%);
$grade-approved-excellence-color: #5faa45;
$grade-failed-color: #e57171;
$grade-notset-color: #797878;

$gray-600: #777777;

$theme-colors: (
    "primary": $primary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
);
