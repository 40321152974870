.btn-icon {
  cursor: pointer;
  @include rem-fallback(min-width, 20);
  object-fit: fill;
}

.icon-right-top {
  top: -1rem;
  position: absolute;
  right: -0.5rem;
}

.done-icon {
  bottom: -1rem;
  position: absolute;
  right: -0.5rem;
  width: 3rem !important;
}

.done-icon-left-top {
  top: -1rem;
  position: absolute;
  left: -0.5rem;
  width: 3rem !important;
}

.done-icon-right-top {
  top: -1rem;
  position: absolute;
  right: -0.5rem;
  width: 3rem !important;
}

.icon-right-top {
  top: -1rem;
  position: absolute;
  right: -0.5rem;
}

.header-icon {
  @include rem-fallback(height, 34);
  @include rem-fallback(width, 34);
  @include rem-fallback(margin-right, 20);
  object-fit: fill;
}

.print-icon {
  @include rem-fallback(min-width, 20);
}

.icon-medium {
  @include rem-fallback(height, 20);
  @include rem-fallback(width, 20);
}

.icon-small {
  @include rem-fallback(height, 14);
  @include rem-fallback(width, 14);
}

.icon-xs {
  @include rem-fallback(height, 12);
  @include rem-fallback(width, 12);
}

.filter-gray {
  filter: brightness(0) saturate(100%) invert(84%) sepia(17%) saturate(0%) hue-rotate(172deg) brightness(100%)
    contrast(87%);
}

.filter-white {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%)
    contrast(102%);
}

.filter-red {
  filter: brightness(0) saturate(100%) invert(62%) sepia(87%) saturate(1110%) hue-rotate(311deg) brightness(89%)
    contrast(99%);
}

.filter-green {
  filter: brightness(0) saturate(100%) invert(54%) sepia(34%) saturate(705%) hue-rotate(60deg) brightness(101%)
    contrast(92%);
}

.filter-primary {
  filter: brightness(0) saturate(100%) invert(54%) sepia(34%) saturate(705%) hue-rotate(60deg) brightness(101%)
    contrast(92%);
}

.avatar-wrapper {
  @include rem-fallback(width, 30);
  margin: 0;
  @include rem-fallback(margin-right, 15);
}

.avatar-img {
  width: 3rem;
  height: 3rem;
  &.small {
    width: 2rem;
    height: 2rem;
  }
}
